import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/core/api.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'payment-lists',
  templateUrl: './payment-lists.component.html',
  styleUrls: ['./payment-lists.component.css']
})
export class PaymentListsComponent implements OnInit {
  response: any;
  bills: any[] = [];
  payment: any = [];
  loading: boolean = true;
  lists: any[] = [];
  codes: any[] = [];
  search: string = ''; total = 0;
  currency = environment.currency;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;

  constructor(public api: ApiService) { }

  ngOnInit() {
    this.getAll();
    this.lists = [];
    this.codes = [];
  }
  
  // Get  list
  getAll() {
    this.loading = true;
    this.api.post('student/fees/non-mandatory?page=1&limit=10', []).subscribe((data: any) => {
      this.response = data;
      this.bills = data.data;
      this.loading = false;
      this.api.Toast.fire({
        icon: 'success',
        title: `<small><b>Fetched Non Mandatory Fees </b> </small>`,
      })
    }, (err: HttpErrorResponse) => {
      this.loading = false;
      this.api.error(err);
    });
  }

  getMore(page: number = 1, limit: number = 10) {
    this.api.get('student/fees/non-mandatory?search=' + this.search + '&page=' + page + '&limit=' + limit).subscribe((data: any) => {
      this.response = data;
      this.bills = data.data;
      // console.log(data);
    });
  }

  Selected(val){
    this.codes.push(val.code);
    var bill = { code: val.code, fee: val.feeType_Name, bill_amount: val.amount, 
        amount_payable: val.balance_Payable, amount: val.balance_Payable, currency: val.currency };
    this.lists.push(bill)
    this.total += (val.balance_Payable);
  }

  RemoveSelected(val){
    this.codes = this.codes.filter((c)=> c != val.code);
    this.lists = this.lists.filter((c)=> c.code != val.code);
    this.total -= (val.balance_Payable);
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getAll();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getAll();
  }

}
