<header id="page-topbar">
    <div class="navbar-header text-white">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box text-white">
                <a routerLink="/" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="assets/img/logo.png" alt="" height="22">
                        <span class="title"></span> <span class="subtitle"> {{title | titlecase}}</span>
                    </span>
                    <span class="logo-lg">
                        <img src="assets/img/logo.png" alt="" height="40" width="50">
                        <span class="title"></span> <span class="subtitle"> {{title | titlecase}}</span>
                    </span>
                </a>

                <a routerLink="/" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="assets/img/logo.png" alt="" height="22">
                        <span class="title"></span> <span class="subtitle"> {{title | titlecase}}</span>
                    </span>
                    <span class="logo-lg">
                        <img src="assets/img/logo.png" alt="" height="40" width="50">
                        <span class="title"></span> <span class="subtitle"> {{title | titlecase}}</span>
                    </span>
                </a>
            </div>

            <button (click)="openSidebar()" type="button" class="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn">
                <i class="fa fa-fw fa-bars text-white"></i>
            </button>

        </div>

        <div class="d-flex">

            <div class="dropdown d-inline-block language-switch">
                <button type="button" class="btn header-item waves-effect" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img id="header-lang-img" src="assets/img/flags/english.jpg" alt="Header Language" height="16">
                </button>
                <div class="dropdown-menu dropdown-menu-right">
        
                    <!-- item-->
                    <a href="javascript:void(0);" class="dropdown-item notify-item language" data-lang="eng">
                        <img src="assets/img/flags/english.jpg" alt="user-image" class="mr-1" height="12"> 
                        <span class="align-middle">English</span>
                    </a>
                    <!-- item-->
                    <a href="javascript:void(0);" class="dropdown-item notify-item language" data-lang="sp">
                        <img src="assets/img/flags/french.png" alt="user-image" class="mr-1" height="12"> 
                        <span class="align-middle">French</span>
                    </a>
                </div>
            </div>

            <div class="dropdown d-inline-block">
                <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <!-- <span class="fs-16 mr-2 font-weight-bold text-white"> <i class="fas fa-cog"></i> Settings</span> -->
                    <a (click)="logOut()"><i class="uil uil-sign-out-alt font-size-16 align-middle mr-1 text-muted"></i> <span class="align-middle text-white" key="t-sign-out"> Sign out</span></a>
                    <!-- <a routerLink="/auth/login" (click)="logOut()"><i class="uil uil-sign-out-alt font-size-16 align-middle mr-1 text-muted"></i> <span class="align-middle text-white" key="t-sign-out"> Sign out</span></a> -->
                </button>
            </div>
        </div>
    </div>
</header>