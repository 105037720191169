import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/core/api.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'request-lists',
  templateUrl: './request-lists.component.html',
  styleUrls: ['./request-lists.component.css']
})
export class RequestListsComponent implements OnInit {

  response: any;
  show: boolean = false;
  requests: any[] = [];
  lists: any[] = [];
  codes: any[] = [];
  search: string = ''; 
  total = 0;
  currency = environment.currency;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(public api: ApiService) { }

  ngOnInit() {
    this.getAll();
    this.show = false
  }

    // Get  list
    getAll() {
      this.api.get('student/fees/types?page=1&limit=10').subscribe((data: any) => {
        this.response = data;
        this.requests = data.data;
        this.Toast.fire({
          icon: 'success',
          // title: 'request list fetched',
          title: `<small><b> request list fetched </b></small>`,
        })
      }, (err: HttpErrorResponse) => {
        this.Toast.fire({
          icon: 'error',
          // title: 'unable to fetch request list',
          title: `<small> <b> ${err} </b></small>`,
        })
      });
    }

  getMore(page: number = 1, limit: number = 10) {
    this.api.get('student/fees/types?search=' + this.search + '&page=' + page + '&limit=' + limit).subscribe((data: any) => {
      this.response = data;
      this.requests = data.data;
      console.log(data);
    });
  }
  

  Selected(val){
    this.codes.push(val.code);
    val.quantity =1;
    this.lists.push(val)
    this.total += (val.amount);
  }

  RemoveSelected(val){
    this.codes = this.codes.filter((c)=> c != val.code);
    this.lists = this.lists.filter((c)=> c.code != val.code);
    this.total -= (val.amount);
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getAll();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getAll();
  }

}
