
          <form role="form" method="Post" #profileForm="ngForm" (ngSubmit)="profile()">
            <div class="mb-3 form-group">
                <label class="control-label dark" for="title" translate>Title</label>
                <select class="form-control select2-search-disable select2-hidden-accessible" [(ngModel)]="pro.title" name="title" aria-hidden="true">
                  <option value="">Choose title</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                </select>
            </div>

            <div class="mb-3 form-group">
                <label class="control-label dark" for="fullName" translate>Full Name</label>
                <input type="text" class="form-control" [(ngModel)]="pro.fullname" name="fullname" placeholder="{{'Full Name ' | translate }}">
            </div>
  
            <div class="mb-3 form-group">
                <label class="control-label dark" for="mobile" translate>Phone Number</label>
                <input type="tel" class="form-control" [(ngModel)]="pro.mobile" name="mobile" placeholder="{{'Phone Number' | translate }}">
            </div>
  
            <div class="form-group">
              <label class="control-label dark" for="email" translate>Email</label>
              <input class="form-control" type="email" [(ngModel)]="pro.email" name="email" placeholder="{{'Email' | translate }}"/>
            </div>

            <div class="mb-3 form-group">
                <label class="control-label dark" for="dob" translate>Date of Birth</label>
                <input type="date" class="form-control" [(ngModel)]="pro.dob" name="dob" placeholder="{{'DOB' | translate }}">
            </div>
  
            <div class="form-group">
              <label class="control-label dark" for="pob" translate>Place of Birth</label>
              <input class="form-control" type="text" [(ngModel)]="pro.pob" name="pob" placeholder="{{'PLACE_OF_BIRTH' | translate }}"/>
            </div>

            <div class="mb-3 form-group">
                <label class="control-label dark" for="nationality" translate>Nationality</label>
                <select class="form-control select2-search-disable select2-hidden-accessible" [(ngModel)]="pro.nationality" name="nationality" aria-hidden="true">
                  <option value="">Choose a country</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Nigeria">Nigeria</option>
                </select>
            </div>

            <div class="mb-3 form-group">
                <label class="control-label dark" for="validationCustom01" translate>Marital Status</label>
                <select class="form-control select2-search-disable select2-hidden-accessible" [(ngModel)]="pro.maritalStatus" name="maritalStatus" aria-hidden="true">
                  <option value="">Choose your relationship status</option>
                  <option value="Married">Married</option>
                  <option value="Single">Single</option>
                </select>
            </div>
  
            <div class="mb-3 form-group">
                <label class="control-label dark" for="address">{{'ADDRESS' | translate }}</label>
                <textarea class="form-control" [(ngModel)]="pro.address" name="address" rows="3"></textarea>
            </div>

            <div>
              <span class="dark">Hostel Required ?</span>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="hostel" [(ngModel)]="pro.hostel" id="exampleRadiosA" value="yes">
                <label class="form-check-label" for="exampleRadiosA">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="hostel" [(ngModel)]="pro.hostel" id="exampleRadiosB" value="no">
                <label class="form-check-label" for="exampleRadiosB">
                  No
                </label>
              </div>
            </div>
             <br>
  
            <div>
              <span class="dark">Disability that require special attention ?</span>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="disability" [(ngModel)]="pro.disability" id="exampleRadios1" value="yes">
                <label class="form-check-label" for="exampleRadios1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="disability" [(ngModel)]="pro.disability" id="exampleRadios2" value="no">
                <label class="form-check-label" for="exampleRadios2">
                  No
                </label>
              </div>
            </div>
             <br>
  
            <div class="mb-3 form-group">
                <label class="control-label dark" for="explanation">{{'IF_YES' | translate }}</label>
                <input type="text" class="form-control" [(ngModel)]="pro.explanation" name="explanation">
            </div>
            <button type="submit" [disabled]="!profileForm.valid" class="btn btn-danger">update</button>
            <br />
          </form>