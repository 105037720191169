
<form class="custom-validation" role="form"  #cpForm="ngForm"  (ngSubmit)="ChangePassword()">
    <div class="mb-3">
        <label class="form-label" translate>Old Pin</label>
        <input class="form-control" [(ngModel)]="cp.oldpin" name="oldpin" type="password" pattern=".{6}" maxlength="6" placeholder="*******" required autocomplete="old-password">
        <span class="text-muted font-size-10" translate>e.g "current pin"</span>
    </div>
    <div class="mb-3">
        <label class="form-label" translate>New Pin</label>
        <input class="form-control" [(ngModel)]="cp.newpin" name="newpin" type="password" pattern=".{6}" maxlength="6" placeholder="*******" required autocomplete="new-password">
        <span class="text-muted font-size-10" translate>e.g "new pin"</span>
    </div>
    <button class="btn btn-warning" data-toggle="modal" data-target="#staticBackdrop" [disabled]="!cpForm.valid" type="button"><strong translate>Change Pin</strong></button>

    <!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#staticBackdrop">
    Launch static backdrop modal
  </button> -->
  
  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Pin Change Warning</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          You are about to change your pin!!! if this was a mistake please close this window.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" id="close">Close</button>
          <button type="submit" class="btn btn-danger"> <span *ngIf="saving == false"> Confirm, change pin</span> <span *ngIf="saving == true">please wait <i class="fas fa-spinner fa-spin"></i> </span> </button>
        </div>
      </div>
    </div>
  </div>
</form>

<br />