
<!-- <div class="d-flex flex-direction-md-column align-items-center mt-2">
    <div class="col-md-6">
        <p class="mt-4 text-left dark bold fs-12 mb-1">Wallet Balance:</p>
        <h5 class="text-left">{{api?.wallet?.balance | currency:api?.wallet?.currency+' '}}</h5>
    </div>
    <div class="col-md-4  text-right">
        <p class="dark bold fs-12 mb-1" translate>Total Selected Bill Amount</p>
        <h5>{{total|currency:currency+' '}}</h5>
    </div>
    <div class="col-md-2 text-right">
        <div class="mb-3">
            <app-payment-form [total]="total" [bills]="lists" (complete)="ngOnInit()"></app-payment-form>
        </div>
    </div>
</div> -->

<div class="">
    <div class="row">
      <div class="col">
        <p class="mt-4 text-left dark bold fs-12 mb-1">Wallet Balance:</p>
        <h4 class="text-left fw-bold">{{api?.wallet?.balance | currency:api?.wallet?.currency+' '}}</h4>
      </div>
      <div class="col-md-auto">
        <p class="dark bold fs-12 mb-1" translate>Total Selected Bill Amount</p>
        <h5>{{total|currency:currency+' '}}</h5>
      </div>
      <div class="col col-lg-2">
        <app-payment-form [total]="total" [bills]="lists" (complete)="ngOnInit()"></app-payment-form>
      </div>
    </div>
  </div>

<div class="table-responsive">
    <table
    class="table table-centered datatable dt-responsive nowrap table-card-list table-check dataTable no-footer dtr-inline"
    style="border-collapse: collapse; border-spacing: 0px 8px; width: 100%;"
    id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
    <thead>
        <tr class="bg-transparent" role="row">
            <!-- <th style="width: 24px;" class="sorting_disabled" rowspan="1" colspan="1">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="checkAll">
                    <label class="custom-control-label" for="checkAll"></label>
                </div>
            </th> -->
            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                colspan="1" style="width: 80px;"
                aria-label="Date: activate to sort column ascending" translate>Code</th>
            <th class="sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                colspan="1" style="width: 200px;" aria-sort="ascending"
                aria-label="Invoice ID: activate to sort column descending" translate>Description</th>
            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                colspan="1" style="width: 100px;"
                aria-label="Billing Name: activate to sort column ascending" translate>Bill Amount</th>
            <!-- <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                colspan="1" style="width: 129px;"
                aria-label="Amount: activate to sort column ascending" translate>Adjustment</th> -->
            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                colspan="1" style="width: 110px;"
                aria-label="Status: activate to sort column ascending" translate>Amount Paid</th>
            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                colspan="1" style="width: 100px;"
                aria-label="Download Pdf: activate to sort column ascending" translate>Status</th>
            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                colspan="1" style="width: 110px;"
                aria-label="Status: activate to sort column ascending" translate>Current Payable</th>
            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                colspan="1" style="width: 100px;"
                aria-label="Download Pdf: activate to sort column ascending" translate>Action</th>
        </tr>
    </thead>
        <tbody *ngIf="mandatory.length !== 0">
            <tr *ngFor="let m of mandatory | paginate  : { itemsPerPage: tableSize, currentPage: page, totalItems: count }; let i = index" role="row" class="odd">
                <!-- <td class="dtr-control">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="invoicecheck1">
                        <label class="custom-control-label" for="invoicecheck1"></label>
                    </div>
                </td> -->

                <td class="middle"> {{m?.code}} </td>

                <td class="middle"> {{m?.feeType_Name}} </td>

                <td class="center middle"> {{m?.amount | currency:m?.currency+' '}}</td>

                <!-- <td class="center middle">0.00</td> -->

                <td class="center middle"> {{m?.amountPaid | currency:m?.currency+' '}}</td>
                
                <td class="center middle"> {{m?.status}} </td>

                <td class="center middle"> {{m?.balance_Payable | currency:m?.currency+' '}}</td>

                <td class="center middle"> 
                    <button *ngIf="!codes.includes(m.code)" (click)="Selected(m)" class="btn btn-outline-dark btn-sm"> Select  </button>  
                    <button *ngIf="codes.includes(m.code)" (click)="RemoveSelected(m)" class="btn btn-outline-white btn-danger btn-sm"> Cancel  </button> 
                </td>
            </tr>
        </tbody>
    </table>
    
    <div class="border-0 p-0 m-0">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)" class="text-center"> </pagination-controls>
      </div>
      

    <br />
    <br />
    <section align="left" *ngIf="mandatory.length == 0">
        <h3 class="font-weight-bold font-size-18 text-danger" translate> PAYMENT_FOOTNOTE</h3>
        <p translate> PAYMENT_FOOTNOTE_DESCRIPTION</p>
    </section>
    
</div>
<!-- <pagination></pagination> -->