import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { ApiService } from 'src/app/services/core/api.service';
import { FileUploadService } from 'src/app/services/core/file-upload.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.css'],
})
export class RegisterFormComponent implements OnInit {
  /*########################## File Upload ########################*/
  @ViewChild('fileInput') el: ElementRef;
  imageUrl: any =
    'https://i.pinimg.com/236x/d6/27/d9/d627d9cda385317de4812a4f7bd922e9--man--iron-man.jpg';
  editFile: boolean = true;
  removeUpload: boolean = false;
  selectedOption: boolean = true;
  selectedOption1: boolean = false;
  hasNormalHealth: boolean = true;
  hasNormalHearing: boolean = true;
  hasNormalEyesight:boolean = true;
  dateForExam: string = 'Saturday, July 20, 2024';
  venueForExam: string = 'Knutsford University College, East Legon - Accra';
  referralSource: string = '';
  fstatus: string = '';
  mstatus: string = '';
  selectedCourse: any;
  relations: any[] = [
  ];
  father: any;
  mother: any;
  guardian: any;
  selectedFile: File | null = null;
  previewUrl: string | ArrayBuffer | null = null;
  tooltipVisible = false;

  public institutions: any[] = [
    {
      id: 1,
      institutionName: '',
      certificate: '',
      dateAwarded: '',
      IndexNumber: '',
      region: '',
      dateStarted: '',
      location: '',
    },
  ];

  // public relations: any[] = [
  //   {

  //   },
  //   {

  //   },
  //   {

  //   }
  // ]

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  SearchCountry = [SearchCountryField.Iso2, SearchCountryField.Name];
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Ghana];
  countries:any[] = [];
  departments: any[] = [];
  courses: any[] = [];
  submitted: Boolean = false;
  reg: any;
  res: any;
  @ViewChild('registerForm') public any: NgForm;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  constructor(
    private api: ApiService,
    private fileUploadService: FileUploadService,
    private router: Router,
    private route: ActivatedRoute
  ) // private _globals: GlobalsService
  {
    this.initializeForm();
    
  }
  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    this.previewFile();
    this.onUpload(this.selectedFile)
  }

  previewFile(): void {
    if (!this.selectedFile) {
      this.previewUrl = null;
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = () => {
      this.previewUrl = reader.result;
    };

  }
  toggleTooltip() {
    this.tooltipVisible = !this.tooltipVisible;
  }
  onUpload(thefile: File ): void {
    if (this.selectedFile) {
      this.fileUploadService.uploadFile("files/upload", thefile).subscribe(
        (response) => {
          this.reg.photoUrl = response.data
          console.log('Upload successful!', response);
        },
        (error) => {
          console.error('Upload failed!', error);
        }
      );
    } else {
      // console.error('No file selected.');
    }
  }

  ngOnInit(): void {
    this.init();
    this.getDepartments();
    this.getCourses();
    this.getCountries();
    // this.getCourses();
    this.route.queryParams.subscribe((data) => {
      // console.log(data);
      this.reg.voucherCode = data.voucher_code;
    });
  }
  initializeForm() {
    this.father = {
      name: '',
      relationship: 'Father',
      mobile: '',
      email: '',
      postalAddress: '',
      address: '',
      nationality: '',
      occupation: '',
      status: '',
      note: 'Father',
    };
    this.mother = {
      name: '',
      relationship: 'Mother',
      mobile: '',
      email: '',
      postalAddress: '',
      address: '',
      nationality: '',
      occupation: '',
      status: '',
      note: 'Mother',
    };
    this.guardian = {
      name: '',
      relationship: 'Guardian',
      mobile: '',
      email: '',
      postalAddress: '',
      address: '',
      nationality: '',
      occupation: '',
      note: '',
    };
  }

  uploadFile(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
        // this.registrationForm.patchValue({
        //   file: reader.result
        // });
        this.editFile = false;
        this.removeUpload = true;
      };
      // ChangeDetectorRef since file is loading outside the zone
      // this.cd.markForCheck();
    }
  }

  // Function to remove uploaded file
  removeUploadedFile() {
    let newFileList = Array.from(this.el.nativeElement.files);
    this.imageUrl =
      'https://i.pinimg.com/236x/d6/27/d9/d627d9cda385317de4812a4f7bd922e9--man--iron-man.jpg';
    this.editFile = true;
    this.removeUpload = false;
    // this.registrationForm.patchValue({
    //   file: [null]
    // });
  }

  // Submit Registration Form
  onSubmit() {
    this.submitted = true;
    // if(!this.registrationForm.valid) {
    //   alert('Please fill all the required fields to create a super hero!')
    //   return false;
    // } else {
    //   console.log(this.registrationForm.value)
    // }
  }

  addInstitution() {
    this.institutions.push({
      id: this.institutions.length + 1,
      institutionName: '',
      certificate: '',
      dateAwarded: '',
      indexNumber: '',
      region: '',
      dateStarted: '',
      location: '',
    });
  }

  removeInstitution(i: number) {
    this.institutions.splice(i, 1);
  }
  onCourseChange(course: any) {
    this.reg.courseId = course.id;
    this.reg.departmentId = course.departmentFk.id;
  }
  init() {
    this.relations = [this.father, this.mother, this.guardian];
    this.reg = {
      
      voucherCode: '',
      photoUrl: '',
      type: 'WAASCE',
      title: 'Mr',
      lastName: '',
      firstName: '',
      mobile: '',
      email: '',
      dateOfBirth: '',
      placeOfBirth: '',
      maritalStatus: 'N/a',
      countryId: null,
      address: 'Accra',
      sponsorship: null,
      languagesSpoken: '',
      favoriteSport: '',
      enrollment: 'n/a',
      Certificate: 'BECE',
      hostel: true,
      subject: '',
      disability: false,
      // status: null,
      disabilityDesc: '',
      employer: '',
      departmentId: null,
      courseId: null,
      secondCourseId: null,
      sessionId: 1,
      emmergencyContact: '',
      gender: null,
      officialTranscript: false,
      otherAcademicRecords: false,
      certifiedTrueCopyOfCertificates: false,
      copyOfBirthCertificate: false,
      copyOfGhanaCard: false,
      bioDataPageOfPassport: false,
      englishProficiencyCertified: false,
      translatedResultSlip: false,
      waecResultCard: false,
      waecResults: false,
      hasNormalHealth: true,
      hasNormalHearing: true,
      hasNormalEyesight: true,
      healthRelatedInfo: '',
      dateForExam: this.dateForExam,
      venueForExam: this.venueForExam,
      referralSource: '',
  
      // age :'',
      // explanation :'',
      passportNumber: '',
      // mailingAddress :'',
      // applicant: '',
      awaitingResults: false,
      indexNumber: false,
      indexNumberDesc: '',
      passportSizePictures: false,
      // baptism_card: false,
      // birth_cert_copy: false,
      // weighting_card: false,
      institutions: this.institutions,
      relations: this.relations,
      // subject: null,
      campusId: 1,
      notes: "",
      referralSourceOther: "",
    

      schoolCode: environment.schoolCode,
    };
  }

  getDepartments() {
    this.api
      .post('departments', { schoolCode: environment.schoolCode })
      .subscribe(
        (data: any) => {
          this.departments = data.data;
          // console.log(data);
        },
        (err: HttpErrorResponse) => {
          this.Toast.fire({
            icon: 'error',
            title: `<small> <b> ${err}! </b></small>`,
          });
        }
      );
  }

  getCountries() {
    this.api
      .post('countries', { schoolCode: environment.schoolCode })
      .subscribe(
        (data: any) => {
          this.countries = data.data;
          // console.log(data);
        },
        (err: HttpErrorResponse) => {
          this.Toast.fire({
            icon: 'error',
            title: `<small> <b> ${err}! </b></small>`,
          });
        }
      );
  }


  getCourses() {
   
    //get courses by department code
      this.api
        .post('courses', {schoolCode: environment.schoolCode, departmentCode: environment.departmentCode  })
        .subscribe(
          (data: any) => {
            this.courses = data.data;
            // console.log({COURSES:this.courses});
          },
          (err: HttpErrorResponse) => {
            this.Toast.fire({
              icon: 'error',
              title: `<small> <b> ${err}! </b></small>`,
            });
          }
        );
  }

  submit() {
    // console.log(this.reg);
    this.submitted = true;
    // Extract e164Number from tempMobile
    this.father.mobile = this.father.tempMobile.e164Number;
    this.mother.mobile = this.mother.tempMobile.e164Number;
    this.guardian.mobile = this.guardian.tempMobile.e164Number;
    // Clear the relations array
    this.relations = [this.father, this.mother, this.guardian];

    this.reg.mobile = this.reg.mobile.e164Number
   
    

    // Log the relations array to the console
    // console.log(this.relations);
    // this.reg.emmergencyContact = this.reg.emmergencyContact.e164Number;
    // this.reg.departmentId = this.reg.departmentId.id;
    //  console.log({REG: this.reg});
    if(this.reg.referralSource == 'Other') {
      this.reg.referralSource = this.reg.referralSourceOther
    }
    
    this.api.post('submit-application/', this.reg).subscribe(
      (data: any) => {
        this.res = data;
        this.Toast.fire({
          icon: 'success',
          title: `<small> <b> Application successfully submitted... </b></small>`,
        });
        this.reg = '';
        // this.submitted = true;
        this.route.queryParams.subscribe((data) => {
          // console.log(data);
          this.reg.voucherCode = data.voucher_code;
        });
        this.router.navigate(['/admissions/application']);
      },
      (err: HttpErrorResponse) => {
        this.submitted = false;
        this.Toast.fire({
          icon: 'error',
          title: `<small> <b> ${err}! </b></small>`,
        });
      }
    );
  }

  logValue() {
    // console.log(this.institutions);
  }
}
