<div id="my-5">
    <div class="h-100">
        <div class="page-content">
            <div class="container-fluid position-absolute top-50 start-50 translate-middle">

                <div class="row center align-items-start justify-content-evenly">
                    <div class="col-md-4">
                        <div class="pb-3 mb-5">
                            <a [routerLink]="['/']" routerLinkActive="router-link-active"> <img class="logo-img" style="width: 200px;"
                                    src="assets/img/logo.png" alt=""></a>
                        </div>
                        <div class="">
                            <h2 class="dark fs-30 fw-bold">School Billing And Payment System</h2><br>
                            <p class="dark">School Billing made easy. Remove friction from managing all <br
                                    class="d-lg-inline d-md-none d-none"> academic payment processes, end-to-end</p><br>

                            <button routerLink="/auth/voucher"
                                class="font-weight-medium btn cs-red shadow-lg px-4 py-2 mb-5 buy-voucher"> Go Back
                                <img src="assets/img/arrow-right.png" alt=""> </button>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="card">
                            <div class="card-body">

                                <form role="form" method="Post" #voucherForm="ngForm" (ngSubmit)="verifyVoucher()">
                                    <div class="mb-3">
                                        <label class="form-label">{{'VOUCHER_CODE' | translate }}</label>
                                        <input [(ngModel)]="vCode.voucher_code" type="text" name="voucher_code"
                                            class="form-control" placeholder="*******" required>
                                    </div>
                                    <button [disabled]="!voucherForm.valid" class="btn btn-danger btn-block"
                                        type="submit"><span *ngIf="submitted == false" class="fw-bold">
                                            {{'VALIDATE_VOUCHER' | translate }} </span> <span
                                            *ngIf="submitted == true">please wait <i class="fas fa-spinner fa-spin"></i>
                                        </span></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>