<!-- <app-calculate-requests *ngIf="show"></app-calculate-requests> -->

<div class="row mt-2" *ngIf="!show">
    <div>
        <div class="row align-items-center">
          <div class="col-6">
            <p class="mt-4 text-left dark bold fs-12 mb-1">Wallet Balance:</p>
            <h4 class="text-left fw-bold">{{api?.wallet?.balance | currency:api?.wallet?.currency+' '}}</h4>
          </div>

          <div class="col-6 text-right">
            <!-- <app-payment-form [total]="total" [bills]="lists" (complete)="ngOnInit()"></app-payment-form> -->
            <button [disabled]="lists.length == 0" (click)="show=true" class="btn btn-dark" translate>Calculate Request</button>
          </div>
        </div>
      </div>
    <!-- <div class="col-md-6 offset-md-6 text-right" *ngIf="requests.length > 0">
        <div class="mb-3">
            <button [disabled]="lists.length == 0" (click)="show=true" class="btn btn-primary" translate>CALCULATE</button>
        </div>
    </div> -->
    <div class="col-md-2 text-right">
        <div class="mb-3">
            <!-- <app-calculate-requests></app-calculate-requests> -->
        </div>  
    </div>
    <div class="col-lg-12">

        <div class="table-responsive"  *ngIf="requests.length > 0">
            <table class="table table-centered datatable dt-responsive nowrap table-card-list table-check dataTable no-footer dtr-inline" style="border-collapse: collapse; border-spacing: 0px 8px; width: 100%;" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                <thead>
                    <tr class="bg-transparent" role="row">
                        <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                            colspan="1" style="width: 80px;"
                            aria-label="Date: activate to sort column ascending" translate>Code</th>
                        <th class="sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                            colspan="1" style="width: 200px;" aria-sort="ascending"
                            aria-label="Invoice ID: activate to sort column descending" translate>FeeType</th>
                            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                            colspan="1" style="width: 100px;"
                            aria-label="Billing Name: activate to sort column ascending" translate>Fee Amount</th>
                        <!-- <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                            colspan="1" style="width: 137px;"
                            aria-label="Date: activate to sort column ascending" translate>Currency</th> -->
                        <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                            colspan="1" style="width: 50px;"
                            aria-label="Download Pdf: activate to sort column ascending" translate>Quantity</th>
                        <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                            colspan="1" style="width: 100px;"
                            aria-label="Billing Name: activate to sort column ascending" translate>Amount Payable</th>
                        <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                            colspan="1" style="width: 100px;"
                            aria-label="Download Pdf: activate to sort column ascending" translate>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let r of requests | paginate  : { itemsPerPage: tableSize, currentPage: page, totalItems: count }; let i = index;" role="row" class="odd">
                        <!-- <td class="dtr-control">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="invoicecheck1">
                                <label class="custom-control-label" for="invoicecheck1"></label>
                            </div>
                        </td> -->

                        <td class="middle"> {{r?.code}} </td>

                        <td class="middle"> {{r?.name}} </td>

                        <td class="center middle"> {{r?.amount | currency:currency+' '}}</td>

                        <!-- <td class="center middle"> <input class="form-control" type="number" value="1" min="1" pattern="[1-9]" readonly> </td> -->
                        <td class="center middle"> 1 </td>

                        <td class="center middle"> {{(r?.amount * (r?.quantity || 1)) | currency:currency+' '}}</td>
                        <!-- <td class="center middle">0.00</td> -->

                        <!-- <td class="center middle"> {{currency}} {{r?.amountPaid}}</td> -->

                        <td class="center middle"> 
                            <button *ngIf="!codes.includes(r.code)" (click)="Selected(r)" class="btn btn-outline-dark btn-sm"> Select  </button>  
                            <button *ngIf="codes.includes(r.code)" (click)="RemoveSelected(r)" class="btn btn-outline-white btn-danger btn-sm"> Remove  </button> 
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <div class="border-0 p-0 m-0">
                <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)" class="text-center"> </pagination-controls>
              </div>
        </div>

        <br />
        <br />
        <section align="left" *ngIf="requests.length == 0">
            <h3 class="font-weight-bold font-size-18 text-danger"> {{'PAYMENT_FOOTNOTE' | translate}}</h3>
            <p> {{'PAYMENT_FOOTNOTE_DESCRIPTION' | translate}}</p>
        </section>
    </div>
</div>

<calculate-lists *ngIf="show" [fees]="lists" [total]="total" (complete)="ngOnInit()"></calculate-lists>
<!-- <pagination></pagination> -->