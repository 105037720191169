import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/core/api.service';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SigninService } from 'src/app/services/features/signin.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.css']
})
export class ProfileFormComponent implements OnInit {

  submitted : Boolean = false; pro: any; res: any; 
  @ViewChild('profileForm') public any: NgForm;
  id: number;
  
  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.init()
  }

  init() {
    this.pro = { title: '', fullname: '', mobile: '', email: '', dob: '', pob: '', nationality: '', date: new Date()};
  }

  profile() {
    console.log(this.pro)
    this.api.put('profile/' + this.pro.id, this.pro).subscribe((data: any) => {
      this.res = data;
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'profile updated',
        showConfirmButton: false,
        timer: 1500
      })
    }, (err: HttpErrorResponse) => {
      this.Toast.fire({
        icon: 'error',
        title: 'Unable to update profile',
      })
    });
  }

}
