export const environment = {
  production: false,
  // apiUrl: "https://maximus-ussd-api.herokuapp.com/schools/",
  apiUrl: "https://sch-proxy-api.cross-switchghana.com/schools/",
  apius: "https://sch-proxy-api.herokuapp.com/schools",
  imgurl: "https://api.cross-switch.app:86/Files/",
  school: "",
  schoolCode: 'hcollege',
  currency : 'GHS',
  secretKey: '',
  mobile: true,
  firebaseConfig: {
    apiKey: "AIzaSyAokxAeC9KtT6hKSkpzMV0kCQhusJWU-bs",
    authDomain: "sch-billing-system.firebaseapp.com",
    projectId: "sch-billing-system",
    storageBucket: "sch-billing-system.appspot.com",
    messagingSenderId: "1022852369901",
    appId: "1:1022852369901:web:1cb01ebd48a62adc33ebe4",
    measurementId: "G-4R0T0RRCBF"
  },
  schoolConfig: {
    name: "Hope College",
    abbr: "HOPE",
    logo: "assets/img/logo.png",
    color: "success", // success (green) || primary (blue) || danger (red) || warning (yellow) 
    colorcode: "#151e33",
    
  }
  
};
