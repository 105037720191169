
          <div class="text-left mb-4">
            <div class="avatar-xl p-1 border border-soft-primary">
                <img src="assets/images/users/avatar-4.jpg" alt="" class="img-fluid">
            </div>
            <h5 class="mt-4 mb-2">Marie Hensley</h5>
            <p class="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-monitor icon-xs mr-1 icon">
                    <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                    <line x1="8" y1="21" x2="16" y2="21"></line>
                    <line x1="12" y1="17" x2="12" y2="21"></line>
                </svg> Web Developer</p>
            

          <p class="mt-4 dark bold fs-12 mb-1">Fullname</p>
          <h5>Test Exmaple John Doe</h5>

          <p class="mt-4 dark bold fs-12 mb-1">Student Number</p>
          <h5>AGHS01298748322</h5>

          <p class="mt-4 dark bold fs-12 mb-1">Student Year / Class</p>
          <h5>September 21/22</h5>

          <p class="mt-4 dark bold fs-12 mb-1">Student Contact</p>
          <h5>+2330205489398</h5>

          <p class="mt-4 dark bold fs-12 mb-1">Student Email</p>
          <h5>johndoeexmaple@example.com</h5>
        </div>


        