<form [formGroup]="form" (ngSubmit)="AddAdmissionForm()" >
    <div class="row">
        <div class="col-md-12">
            <div class="mb-3">
                <label class="form-label" for="certificate">{{'CERTIFICATE' | translate}}</label>
                <input type="text" class="form-control" formControlName="certificate" placeholder="certificate" />
            </div>

            <div class="mb-3 form-group">
                <label class="control-label dark" for="dateAwarded">{{'DATE_AWARDED' | translate}}</label>
                <input type="date" class="form-control" formControlName="dateAwarded" placeholder="Date Awarded">
            </div>

            <div *ngFor="let a of arr">
                <div class="row">
                    <div class="col-md-6 col-12">
                        <div class="mb-3">
                            <label class="form-label" for="grade">{{'GRADE' | translate}}</label>
                            <select class="form-control select2-search-disable select2-hidden-accessible" formControlName="grade" aria-hidden="true">
                                <option value="" translate>Choose Grade</option>
                                <option value="A" translate>A</option>
                                <option value="B" translate>B</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="mb-3">
                            <label class="form-label" for="subject">{{'SUBJECT' | translate}}</label>
                            <select class="form-control select2-search-disable select2-hidden-accessible" formControlName="subject" aria-hidden="true">
                                <option value="" translate>Choose</option>
                                <option value="English">English</option>
                                <option value="Maths">Maths</option>
                            </select>
                        </div>
                    </div>
                </div>

            </div>
            <!-- <button type="submit" (click)="addNewForm()" class="btn btn-outline-dark btn-sm pull-right mb-2">
                {{'ADD_NEW' | translate}}
            </button> -->
            <button type="submit" class="btn btn-outline-dark btn-sm pull-right mb-2">
                {{'Update' | translate}}
            </button>
        </div>
    </div>
</form>