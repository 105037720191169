import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/core/api.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

@Component({
  selector: 'transaction-lists',
  templateUrl: './transaction-lists.component.html',
  styleUrls: ['./transaction-lists.component.css']
})
export class TransactionListsComponent implements OnInit {
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [ 10, 20, 30, 40];

  /*name of the excel-file which will be downloaded. */ 
  fileName= 'TransactionList.xlsx';  

  response: any;
  transactions: any[] = [];
  search: string = '';

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(public api: ApiService) {}

  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
			
    }


  ngOnInit() {
    this.getAll();
  }

  // Get  list
  getAll() {
    this.api.post('student/payments?page=1&limit=10', []).subscribe((data: any) => {
      this.response = data;
      this.transactions = data.data;
    this.Toast.fire({
      icon: 'success',
      title: `<small> <b> transaction list fetched </b></small>`,
    })
  }, (err: HttpErrorResponse) => {
    this.Toast.fire({
      icon: 'error',
      title: `<small> <b> ${err} <br> unable to fetch transactions list </b></small>`,
    })
  });
}


  onTableDataChange(event: any) {
    this.page = event;
    this.getAll();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getAll();
  }

}
