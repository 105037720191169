import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError } from '@angular/router';
import { GlobalsService } from 'src/app/services/core/globals.service';
import { environment } from 'src/environments/environment';
declare let $: any;

@Component({
  selector: 'app-topbar-layout',
  templateUrl: './topbar-layout.component.html',
  styleUrls: ['./topbar-layout.component.css']
})
export class TopbarLayoutComponent implements OnInit {

  constructor(
    public _globals: GlobalsService
  ) { 
    this._globals.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) this._globals.title  = event.url.split('/');
        if (event instanceof NavigationError) {
          console.log(event.error);
        }
    }); 
  }

  ngOnInit(): void {
    // $('body').addClass('vertical-collpsed')
    $('body').attr('data-layout', 'horizontal')
    $('body').attr('data-topbar', 'colored')
    $('.d-flex .dropdown').hide();
    $('body[data-layout=horizontal] .navbar-header .title').html(environment.schoolConfig.abbr + " |");
    $('body[data-layout=horizontal] .navbar-header .title').addClass('text-white');
    $('body[data-layout=horizontal] .navbar-header .subtitle').addClass('text-white');
    $('.navbar-brand-box img').attr('src',  environment.schoolConfig.logo)
  }

}
