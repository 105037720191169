<table
    class="table table-centered datatable dt-responsive nowrap table-card-list table-check dataTable no-footer dtr-inline"
    style="border-collapse: collapse; border-spacing: 0px 8px; width: 100%;" id="DataTables_Table_0" role="grid"
    aria-describedby="DataTables_Table_0_info">
    <thead>
        <tr class="bg-transparent" role="row">
            <th class="sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
            colspan="1" style="width: 148px;" aria-sort="ascending"
            aria-label="Invoice ID: activate to sort column descending">Code</th>
            <th class="sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
            colspan="1" style="width: 148px;" aria-sort="ascending"
            aria-label="Invoice ID: activate to sort column descending">Wallet Name</th>
            <th class="sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
            colspan="1" style="width: 148px;" aria-sort="ascending"
            aria-label="Invoice ID: activate to sort column descending">Wallet Balance</th>
            <th class="sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
            colspan="1" style="width: 148px;" aria-sort="ascending"
            aria-label="Invoice ID: activate to sort column descending">Status</th>
            <!-- <th class="sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
            colspan="1" style="width: 148px;" aria-sort="ascending"
            aria-label="Invoice ID: activate to sort column descending">Active</th> -->
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let w of wallets">
            <td>{{w.code}}</td>
            <td>{{w.student_Name}}</td>
            <td>{{w.balance|currency:w?.currency+' '}}</td>
            <td>Active</td>
            <!-- <td>
                <span class="btn btn-sm btn-outline-dark px-3 py-1">Default</span>
            </td> -->
        </tr>
        <tr hidden>
            <td>USD</td>
            <td>Mobile Money</td>
            <td> 01234567890987 (Mark Saints ) </td>
            <td>Not Verified</td>
            <td>
                <span class="btn btn-sm btn-outline-dark px-3 py-1">Alternative</span>
            </td>
        </tr>
    </tbody>
</table>