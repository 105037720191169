import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/core/api.service';
import { GlobalsService } from 'src/app/services/core/globals.service';
import { UsersService } from 'src/app/services/features/users';

@Component({
  selector: 'transaction-filter-form',
  templateUrl: './transaction-filter-form.component.html',
  styleUrls: ['./transaction-filter-form.component.css']
})
export class TransactionFilterFormComponent implements OnInit {
  response: any;
  filter: any = [];

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.getAll();
  }

  // Get  list
  getAll() {
    this.api.get('student/payments?page=1&limit=8').subscribe((data: any) => {
      this.response = data;
      this.filter = data.data;
    });
  }

  
  Filter() {
    this.api.post('student/payments', this.filter).subscribe((data:any) =>{
    },( err: HttpErrorResponse)=>{
      // console.log(err);
    });
  }

}
