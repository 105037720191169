

    <div id="layout-wrapper my-5">
      <div class="h-100">
          <div class="page-content">
              <div class="container-fluid position-absolute top-50 start-50 translate-middle">
  
                  <div class="row center align-items-start justify-content-evenly">
                      <div class="col-md-4">
                          <div class="pb-3 mb-5">
                               <img style="width: 200px;"
                                      src="assets/img/logo.png" alt="">
                          </div>
                          <div class="">
                              <h2 class="dark fs-30 fw-bold">School Billing And Payment System</h2><br>
                              <p class="dark">School Billing made easy. Remove friction from managing all <br
                                      class="d-lg-inline d-md-none d-none"> academic payment processes, end-to-end</p><br>
                          </div>
                      </div>
                      <div class="col-md-5">
                          <div class="card">
                              <div class="card-body">
  
                                  <h4 class="card-title dark fw-bold">Validate your OTP </h4>
                                  <p class="dark">Enter the OTP sent to you to set your pin</p>
  
  
                                  <form  role="form" method="Post" #otpForm="ngForm" (ngSubmit)="verifyOtp()">
                                    <div class="mb-3">
                                      <label class="control-label dark" for="otp">{{'OTP Code' | translate}}</label>
                                      <input class="form-control" type="text" placeholder="validate otp" pattern=".{6}" maxlength="6" [(ngModel)]="code.otp"  name="otp" required />
                                    </div>
                                    <!-- <button routerLink="/auth/setpin" class="btn btn-danger btn-block" type="button">{{'Validate Code raw' | translate }}</button> -->
                                    <button type="submit" [disabled]="!otpForm.valid" class="btn btn-danger btn-block" translate> <span *ngIf="submitted == false" class="fw-bold"> Validate Code </span>  <span *ngIf="submitted == true">please wait <i class="fas fa-spinner fa-spin"></i> </span></button>
                                    <!-- <p class="dark mt-3 text-right" > Didn't get otp ? <a class="cs-red">resend</a> </p> -->
                                </form>
                                <br />

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>