
    <table
    class="table table-centered datatable dt-responsive nowrap table-card-list table-check dataTable no-footer dtr-inline"
    style="border-collapse: collapse; border-spacing: 0px 8px; width: 100%;"
    id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
    <thead>
        <tr role="row">
            <th class="sorting_desc" role="columnheader" tabindex="0" aria-controls="tblOS" rowspan="1" colspan="1"
                aria-sort="descending" aria-label="Transaction Date: activate to sort column ascending"
                style="width: 147.625px;" translate>Transaction Date</th>
            <th class="sorting" role="columnheader" tabindex="0" aria-controls="tblOS" rowspan="1" colspan="1"
                aria-label="Index Number: activate to sort column ascending" style="width: 128.469px;" translate>Index Number</th>
            <th class="sorting" role="columnheader" tabindex="0" aria-controls="tblOS" rowspan="1" colspan="1"
                aria-label="Entity Name: activate to sort column ascending" style="width: 145.453px;" translate>Entity Name</th>
            <th class="sorting" role="columnheader" tabindex="0" aria-controls="tblOS" rowspan="1" colspan="1"
                aria-label="Fee Type: activate to sort column ascending" style="width: 364.508px;" translate>Fee Type</th>
            <th class="sorting" role="columnheader" tabindex="0" aria-controls="tblOS" rowspan="1" colspan="1"
                aria-label="Fee Amount: activate to sort column ascending" style="width: 110.625px;" translate>Fee Amount</th>
            <th class="sorting" role="columnheader" tabindex="0" aria-controls="tblOS" rowspan="1" colspan="1"
                aria-label="Amount Paid: activate to sort column ascending" style="width: 117.086px;" translate>Amount Paid</th>
            <th class="sorting" role="columnheader" tabindex="0" aria-controls="tblOS" rowspan="1" colspan="1"
                aria-label="Currency: activate to sort column ascending" style="width: 86.328px;" translate>Currency</th>
            <th class="sorting" role="columnheader" tabindex="0" aria-controls="tblOS" rowspan="1" colspan="1"
                aria-label="Qty: activate to sort column ascending" style="width: 43.4375px;" translate>Quantity</th>
            <th class="sorting" role="columnheader" tabindex="0" aria-controls="tblOS" rowspan="1" colspan="1"
                aria-label="Status: activate to sort column ascending" style="width: 83.469px;" translate>Status</th>
        </tr>
    </thead>

    <tbody role="alert" aria-live="polite" aria-relevant="all">
        <tr class="odd">

            <td class="  sorting_1">11/18/2021</td>
            <td class=" ">10020045</td>
            <td class=" ">EMMANUEL ESSEL</td>
            <td class=" ">LIBERTY HALL DUES</td>
            <td class=" ">30</td>
            <td class="text-right ">30</td>
            <td class=" ">GHS</td>
            <td class=" ">1</td>
            <td class=" ">Complete</td>
        </tr>
        <tr class="even">

            <td class="  sorting_1">11/18/2021</td>
            <td class=" ">10020045</td>
            <td class=" ">EMMANUEL ESSEL</td>
            <td class=" ">Undergraduates &amp; Diploma Graduation Fee</td>
            <td class=" ">270</td>
            <td class="text-right ">270</td>
            <td class=" ">GHS</td>
            <td class=" ">1</td>
            <td class=" ">Complete</td>
        </tr>
        <tr class="odd">

            <td class="  sorting_1">11/18/2021</td>
            <td class=" ">10020045</td>
            <td class=" ">EMMANUEL ESSEL</td>
            <td class=" ">Letter Of Attestation</td>
            <td class=" ">5</td>
            <td class="text-right ">5</td>
            <td class=" ">GHS</td>
            <td class=" ">1</td>
            <td class=" ">Complete</td>
        </tr>
        <tr class="even">

            <td class="  sorting_1">11/18/2021</td>
            <td class=" ">10020045</td>
            <td class=" ">EMMANUEL ESSEL</td>
            <td class=" ">Late Return Of Academic Gown</td>
            <td class=" ">50</td>
            <td class="text-right ">50</td>
            <td class=" ">GHS</td>
            <td class=" ">1</td>
            <td class=" ">Submitted</td>
        </tr>
        <tr class="odd">

            <td class="  sorting_1">11/18/2021</td>
            <td class=" ">10020045</td>
            <td class=" ">EMMANUEL ESSEL</td>
            <td class=" ">Post Graduate Graduation Fee</td>
            <td class=" ">270</td>
            <td class="text-right ">270</td>
            <td class=" ">GHS</td>
            <td class=" ">1</td>
            <td class=" ">Submitted</td>
        </tr>
        <tr class="even">

            <td class="  sorting_1">07/07/2021</td>
            <td class=" ">10020045</td>
            <td class=" ">EMMANUEL ESSEL</td>
            <td class=" ">Letter Of Attestation</td>
            <td class=" ">5</td>
            <td class="text-right ">5</td>
            <td class=" ">GHS</td>
            <td class=" ">1</td>
            <td class=" ">Submitted</td>
        </tr>
        <tr class="odd">

            <td class="  sorting_1">11/05/2020</td>
            <td class=" ">10020045</td>
            <td class=" ">EMMANUEL ESSEL</td>
            <td class=" ">Post Graduate Graduation Fee</td>
            <td class=" ">270</td>
            <td class="text-right ">270</td>
            <td class=" ">GHS</td>
            <td class=" ">1</td>
            <td class=" ">Submitted</td>
        </tr>
        <tr class="even">

            <td class="  sorting_1">08/27/2020</td>
            <td class=" ">10020045</td>
            <td class=" ">EMMANUEL ESSEL</td>
            <td class=" ">Resit Registration -Undergraduate &amp; Diploma</td>
            <td class=" ">40</td>
            <td class="text-right ">40</td>
            <td class=" ">GHS</td>
            <td class=" ">2</td>
            <td class=" ">Submitted</td>
        </tr>
        <tr class="odd">

            <td class="  sorting_1">06/18/2020</td>
            <td class=" ">10020045</td>
            <td class=" ">EMMANUEL ESSEL</td>
            <td class=" ">Resit Registration -Undergraduate &amp; Diploma</td>
            <td class=" ">40</td>
            <td class="text-right ">40</td>
            <td class=" ">GHS</td>
            <td class=" ">2</td>
            <td class=" ">Submitted</td>
        </tr>
        <tr class="even">

            <td class="  sorting_1">06/18/2020</td>
            <td class=" ">10020045</td>
            <td class=" ">EMMANUEL ESSEL</td>
            <td class=" ">Post Graduate Graduation Fee</td>
            <td class=" ">270</td>
            <td class="text-right ">270</td>
            <td class=" ">GHS</td>
            <td class=" ">1</td>
            <td class=" ">Approved</td>
        </tr>
    </tbody>
</table>