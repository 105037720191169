
<div id="layout-wrapper">
    <div class="h-100 bg-white">
        <app-header [title]="_globals?.title[1] + ' ' + _globals?.title[2]"></app-header>
        <div class="page-content">
            <hr />
            <div class="container">
                <!-- <app-breadcrumb [title]="(_globals.title.length == 3) ? _globals?.title[1] + ' ' + _globals?.title[2] : _globals?.title[1]"></app-breadcrumb> -->
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>