import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/core/api.service';

@Component({
  selector: 'transaction-info',
  templateUrl: './transaction-info.component.html',
  styleUrls: ['./transaction-info.component.css']
})
export class TransactionInfoComponent implements OnInit {

  transaction: any; id : String;

  constructor(private api: ApiService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    // this.transaction(this.route.snapshot.paramMap.get('id'));
    this.transaction = this.route.snapshot.paramMap.get('id');
  }

  getTransaction(id: string) {
    this.api.get('student/payments/' + id).subscribe((data: any) => {
      this.transaction = data.data;
    });
  }

}
