<div class="float-right">
    <button class="btn btn-dark" (click)="exportexcel()">Export Transactions</button>
</div>

<table id="excel-table"
    class="table table-centered datatable dt-responsive nowrap table-card-list table-check dataTable no-footer dtr-inline"
    style="border-collapse: collapse; border-spacing: 0px 8px; width: 100%;"  role="grid"
    aria-describedby="DataTables_Table_0_info">
    <thead>
        <tr class="bg-transparent" role="row">
            <th class="sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                style="width: 100px;" aria-sort="ascending" aria-label="Invoice ID: activate to sort column descending"
                translate>Transaction Id</th>
            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                style="width: 120px;" aria-label="Date: activate to sort column ascending" translate>Date</th>
            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                style="width: 80px;" aria-label="Billing Name: activate to sort column ascending" translate> Type</th>
            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                style="width: 100px;" aria-label="Amount: activate to sort column ascending" translate>Amount</th>
            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                style="width: 200px;" aria-label="Amount: activate to sort column ascending" translate>Description</th>
            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                style="width: 80px;" aria-label="Status: activate to sort column ascending" translate>status</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let t of transactions | paginate
              : {
                  itemsPerPage: tableSize,
                  currentPage: page,
                  totalItems: count
                };
        
              let i = index" role="row" class="odd">

            <td class="middle"> {{t?.code}} </td>

            <td class="middle"> {{t?.creationTime | date:'short'}} </td>

            <td class="center middle"> {{t?.type}}</td>

            <td class="center middle"> {{t?.amount | currency:t?.currency+' '}}</td>
            
            <td class="center middle"> {{t?.feeType}} </td>

            <td class="center middle"> <button class="btn btn-danger btn-sm fw-bold">{{t?.status_Message}} </button> </td>

        </tr>
    </tbody>
  
</table>

<div class="border-0 p-0 m-0">
    <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)" class="text-center"> </pagination-controls>
  </div>