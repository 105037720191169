
<form role="form" method="Post" #enrollForm="ngForm" (ngSubmit)="Enrollment()">
  <div class="form-group">
    <label class="control-label dark">{{'CAMPUS' | translate}}</label>
    <select class="form-control select2-search-disable select2-hidden-accessible" aria-hidden="true" [(ngModel)]="enroll.campus" name="campus" required>
      <option value="">{{' CHOOSE_CAMPUS' | translate}}</option>
      <option value="knust">{{' KNUST' | translate}}</option>
    </select>
  </div>

  <div class="form-group">
    <label class="control-label dark">{{'DEPARTMENT' | translate}}</label>
    <select class="form-control select2-search-disable select2-hidden-accessible" aria-hidden="true" [(ngModel)]="enroll.department" name="department" required>
      <option value="">{{'CHOOSE_DEPARTMENT' | translate}}</option>
      <option value="ict">{{'ICT' | translate}}</option>
    </select>
  </div>

  <div class="form-group">
    <label class="control-label dark">{{'INTAKE' | translate}}</label>
    <select class="form-control select2-search-disable select2-hidden-accessible" aria-hidden="true" [(ngModel)]="enroll.intake" name="intake" required>
      <option value="">{{'CHOOSE_INTAKE' | translate}}</option>
      <option value="sample">{{'Sample' | translate}}</option>
    </select>
  </div>

  <div class="form-group">
    <label class="control-label dark">{{'SPONSORSHIP' | translate}}</label>
    <select class="form-control select2-search-disable select2-hidden-accessible" aria-hidden="true" [(ngModel)]="enroll.sponsorship" name="sponsorship">
      <option value="">{{'CHOOSE_SPONSORSHIP' | translate}}</option>
    </select>
  </div>

  <div class="form-group">
    <label class="control-label dark">{{'COURSE' | translate}}</label>
    <select class="form-control select2-search-disable select2-hidden-accessible" aria-hidden="true" [(ngModel)]="enroll.course" name="course" required>
      <option value="">{{'CHOOSE_COURSE' | translate}}</option>
      <option value="science">{{'Science' | translate}}</option>
    </select>
  </div>

  <div class="form-group">
    <label class="control-label dark">{{'SESSION' | translate}}</label>
    <select class="form-control select2-search-disable select2-hidden-accessible" aria-hidden="true" [(ngModel)]="enroll.session" name="session" required>
      <option value="">{{'CHOOSE_SESSION' | translate}}</option>
      <option value="sample">{{'sample session' | translate}}</option>
    </select>
  </div>

  <!-- <button type="submit" [disabled]="!enrollForm.valid" class="btn btn-danger btn-block">Save</button> -->
</form>

<br />