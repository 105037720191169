           <!-- NOTIFICATION DETAILS -->
           <div class="offcanvas offcanvas-end" tabindex="-1" id="notificationInfo" aria-labelledby="offcanvasRightLabel">
            <div class="offcanvas-header">
              <h5 id="offcanvasRightLabel"></h5>
              <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <h5 class="offcanvas-title font-weight-bold">NOTIFICATION</h5>
                
                <div class="row">
                    <div class="col-sm-12">
                        <div class="text-muted mt-5">
                            <h5 class="font-size-15 mb-1" translate>TITLE:</h5>
                            <p class="mb-1">{{notification?.title}}</p>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-sm-12">
                        <div class="text-muted">
                            <div class="mt-4">
                                <h5 class="font-size-15 mb-1" translate>DUE DATE:</h5>
                                <p>{{notification?.dueDate|date}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-sm-12">
                        <div class="text-muted">
                            <h5 class="font-size-15 mb-1" translate>NOTIFICATION DETAILS:</h5>
                            <!-- <span class="badge-info badge mr-2" translate>Admin</span> -->
                            <p class="mb-1">{{notification?.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
