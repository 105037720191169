import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/core/api.service';
import { GlobalsService } from 'src/app/services/core/globals.service';
import { UsersService } from 'src/app/services/features/users';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admission-update',
  templateUrl: './admission-update.component.html',
  styleUrls: ['./admission-update.component.css']
})
export class AdmissionUpdateComponent implements OnInit {

  arr: any = [0]
  newAdmissionForm: any = [];
  submitted: Boolean = false;
  form!: FormGroup;
  id!: string;
  res: any;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(private api: ApiService, private _userService: UsersService, private _globals: GlobalsService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      certificate: new FormControl('', [Validators.required]),
      dateAwarded: new FormControl('', Validators.required),
      grade: new FormControl('', Validators.required),
      subject: new FormControl('', Validators.required),
    });
  }

  addNewForm() {
    this.arr.push(this.arr.length + 1)
  }

  AddAdmissionForm() {
    console.log(this.form.value);
    this.api.put('admission' , this.form.value).subscribe((data: any) => {
      this.res = data;
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Admission updated',
        showConfirmButton: false,
        timer: 1500
      });
    },( err: HttpErrorResponse)=>{
      console.log(err)
      this.Toast.fire({
        icon: 'error',
        title: 'unable to update admission',
      })
    });
  }

}

