<div id="my-5">
  <div class="h-100">
      <div class="page-content">
          <div class="container-fluid position-absolute top-50 start-50 translate-middle">

              <div class="row center align-items-start justify-content-evenly">
                  <div class="col-md-4">
                      <div class="pb-3 mb-5">
                          <a [routerLink]="['/']" routerLinkActive="router-link-active"> <img class="logo-img" style="width: 200px;"
                                  src="assets/img/logo.png" alt=""></a>
                      </div>
                      <div class="">
                          <h2 class="dark fs-30 fw-bold">School Billing And Payment System</h2><br>
                          <p class="dark">School Billing made easy. Remove friction from managing all <br
                                  class="d-lg-inline d-md-none d-none"> academic payment processes, end-to-end</p><br>
                      </div>
                  </div>
                  <div class="col-md-5">
                      <div class="card">
                          <div class="card-body">

                            <h4 class="card-title dark fw-bold">Get Started Institution</h4>
                            <p class="dark">Complete the form to save a new institution information on our cxwindow servers for easy access and management.</p>
                            
                            <form role="form" method="Post" #getStartedForm="ngForm" (ngSubmit)="SendOtp()">
                              <div class="row">
                                <div class="wrapper col-md-12">
                                  <label for="mobile">Mobile Number</label>
                                  <div class="form-group">
                                      <ngx-intl-tel-input [cssClass]="'form-control custom'"
                                          [preferredCountries]="preferredCountries"
                                          [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                          [searchCountryFlag]="true" [searchCountryField]="SearchCountry"
                                          [selectFirstCountry]="true"
                                          [selectedCountryISO]="CountryISO.Ghana"
                                          [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                          [numberFormat]="PhoneNumberFormat.National"
                                          [(ngModel)]="get.mobile" name="indexNumber" required>
                                        </ngx-intl-tel-input>
                                  </div>
                              </div>
                              </div>
                               
                                <button type="submit" [disabled]="!getStartedForm.valid" class="btn btn-danger btn-block" translate><span *ngIf="submitted == false" class="fw-bold"> Get Started </span>  <span *ngIf="submitted == true">please wait <i class="fas fa-spinner fa-spin"></i> </span></button>
                            </form>
                            
                            <div class="mt-4 text-left">
                                <hr>
                                <p class="mb-2 dark">  Already have an account?
                                    <a routerLink="/auth/login" class="font-weight-medium cs-red"> Login</a>
                                </p> 
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


<!-- <form role="form" method="Post" #getStartedForm="ngForm" (ngSubmit)="SendOtp()">
  <div class="row">
    <div class="wrapper col-md-12">
      <label for="mobile">Mobile Number</label>
      <div class="form-group">
          <ngx-intl-tel-input [cssClass]="'form-control custom'"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
              [searchCountryFlag]="true" [searchCountryField]="SearchCountry"
              [selectFirstCountry]="true"
              [selectedCountryISO]="CountryISO.Ghana"
              [phoneValidation]="true" [separateDialCode]="separateDialCode"
              [numberFormat]="PhoneNumberFormat.National"
              [(ngModel)]="get.mobile" name="indexNumber" required>
            </ngx-intl-tel-input>
      </div>
  </div>
  </div>
   
    <button type="submit" [disabled]="!getStartedForm.valid" class="btn btn-danger btn-block" translate><span *ngIf="submitted == false" class="fw-bold"> Get Started </span>  <span *ngIf="submitted == true">please wait <i class="fas fa-spinner fa-spin"></i> </span></button>
</form>
<br /> -->