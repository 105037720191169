

<div class="row">
    <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0">{{title | titlecase}}</h4>

            <div class="page-title-right">
                <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="javascript: void(0);">{{name | titlecase}}</a></li>
                    <li class="breadcrumb-item" [ngClass]="(subtitle !== '') ? '' :' active'">{{title | titlecase}} </li>
                    <li class="breadcrumb-item active">{{subtitle | titlecase}}</li>
                </ol>
            </div>
        </div>
    </div>
</div>