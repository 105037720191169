<div id="layout-wrapper my-5">
    <div class="h-100">
        <div class="page-content">
            <div class="container-fluid position-absolute top-50 start-50 translate-middle">

                <div class="row center align-items-start justify-content-evenly">
                    <div class="col-md-4">
                        <div class="pb-3 mb-5">
                             <img style="width: 200px;"
                                    src="assets/img/logo.png" alt="">
                        </div>
                        <div class="">
                            <h2 class="dark fs-30 fw-bold">School Billing And Payment System</h2><br>
                            <p class="dark">School Billing made easy. Remove friction from managing all <br
                                    class="d-lg-inline d-md-none d-none"> academic payment processes, end-to-end</p><br>

                            <button routerLink="/auth/voucher"
                                class="font-weight-medium btn cs-red shadow-lg px-4 py-2 mb-5 buy-voucher"> Apply online
                                <img src="assets/img/arrow-right.png" alt=""> </button>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="card">
                            <div class="card-body">

                                <h4 class="card-title dark fw-bold">Login into your Institution</h4>
                                <p class="dark">Complete the form to log into your student portal</p>


                                <form role="form" method="Post" #loginForm="ngForm" (ngSubmit)="login()">
                                    <div class="wrapper">
                                        <label for="mobile">Mobile Number *</label>
                                        <div class="form-group">
                                            <ngx-intl-tel-input [cssClass]="'form-control custom'"
                                                [preferredCountries]="preferredCountries"
                                                [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                                [searchCountryFlag]="true" [searchCountryField]="SearchCountry"
                                                [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.Ghana"
                                                [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                                [numberFormat]="PhoneNumberFormat.National" [(ngModel)]="log.mobile"
                                                name="indexNumber" required>
                                            </ngx-intl-tel-input>
                                        </div>
                                    </div>
                                    <div class="mb-4">
                                        <label class="form-label" translate>Pin *</label>
                                        <input [(ngModel)]="log.pin" class="form-control" name="pin" type="password"
                                            pattern=".{6}" maxlength="6" placeholder="pin" required>
                                    </div>
                                    <!-- <button type="button" routerLink="/app/overview" routerLinkActive="router-link-active"  class="btn btn-danger btn-block"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-in icon-xs icon mr-1"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg> Login into Student Portal</button> -->

                                    <button type="submit" [disabled]="!loginForm.valid"
                                        class="btn btn-danger btn-block"><svg xmlns="http://www.w3.org/2000/svg"
                                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-log-in icon-xs icon mr-1">
                                            <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                                            <polyline points="10 17 15 12 10 7"></polyline>
                                            <line x1="15" y1="12" x2="3" y2="12"></line>
                                        </svg> <span *ngIf="submitted == false" class="fw-bold"> Login</span> <span
                                            *ngIf="submitted == true">please wait <i class="fas fa-spinner fa-spin"></i>
                                        </span> </button>
                                </form>


                                <div class="mt-4 text-left">
                                    <hr>
                                    <p class="mb-2 dark"> New to the portal?
                                        <a routerLink="/auth/getstarted" class="font-weight-medium cs-red"> Get
                                            Started</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>