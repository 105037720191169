import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/core/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'wallet-lists',
  templateUrl: './wallet-lists.component.html',
  styleUrls: ['./wallet-lists.component.css']
})
export class WalletListsComponent implements OnInit {
  response: any;
  wallets: any[] = [];
  search: string = '';

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(public api: ApiService) {}

  ngOnInit() {
    this.getAll();
  }

  // Get  list
  getAll() {
    this.api.post('student/wallet?page=1&limit=10', []).subscribe((data: any) => {
      this.response = data;
      this.wallets = data.data;
  });
}
}
