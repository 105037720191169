<!-- <a type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" class="btn btn-dark" translate>Make Payment</a> -->

<button data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
  class="btn btn-outline-dark btn-block font-size-14 btn-sm" translate>Add fund</button>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel">Add Funds</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body p-4">
    <form role="form" method="Post" #fundsForm="ngForm" (ngSubmit)="addFunds()">
      <h2 class="m-t-none m-b text-navy text-center" translate> Topup School Wallet</h2>
      <div class="col-sm-12">
        <div class="form-group">
          <label translate>Amount </label>
          <input type="number" class="form-control" [(ngModel)]="funds.amount" name="amount" placeholder="100gh" required>
        </div>
      </div>
      <div class="row p-2">
        <div class="form-group col-sm-6">
          <div class="form-group">
            <label translate>Provider :</label>
            <div class="input-group">
              <ng-select placeholder="Select Mobile Network..." class="input-group w-100" [(ngModel)]="funds.network"
                name="network" #network="ngModel" required>
                <ng-option *ngFor="let n of networks" [value]="n">{{n | titlecase}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class=" form-group col-md-6">
          <label for="mobile">Mobile Money Number</label>
          <div class="form-group">
            <ngx-intl-tel-input class="w-100" [cssClass]="'form-control custom'"
              [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
              [searchCountryFlag]="true" [searchCountryField]="SearchCountry" [selectFirstCountry]="true"
              [selectedCountryISO]="CountryISO.Ghana" [phoneValidation]="true" [separateDialCode]="separateDialCode"
              [numberFormat]="PhoneNumberFormat.National" name="mobile" [(ngModel)]="funds.mobile" name="mobile" required>
            </ngx-intl-tel-input>
          </div>
        </div>


        <div class="form-group col-sm-6">
          <div class="form-group">
            <label translate>Choose Wallet for Topup :</label>
            <div class="input-group">
              <ng-select placeholder="Select Wallet..." class="input-group w-100" [(ngModel)]="funds.wallet" name="wallet" required>
                <!-- <ng-option *ngFor="let w of wallet" [value]="w.code">{{w.name}}</ng-option> -->
                <ng-option *ngFor="let w of wallets" [value]="w.code">wallet balance : {{w.currency}} {{w.balance }} ~ code : {{w.code}} </ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12">
        <!-- <button class="btn btn-danger float-right" [disabled]="!fundsForm.valid" type="submit"><i class="fa fa-share-square-o"></i><strong translate>Add Funds</strong></button> -->
        <button type="submit" [disabled]="!fundsForm.valid"  class="btn btn-danger float-right">  Add Funds  <span *ngIf="submitted == true"><i class="fas fa-spinner fa-spin"></i> </span> </button>
      </div>
    </form>
  </div>
</div>