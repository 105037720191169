<div class="vertical-menu">

    <!-- LOGO -->
    <div class="navbar-brand-box">
        <a href="index.html" class="logo logo-dark">
            <span class="logo-sm">
                <img src="https://admissions.upsa.edu.gh/wp-content/uploads/2020/04/upsa-favi.png" alt="" height="22">
            </span>
            <span class="logo-lg">
                <img src="https://admissions.upsa.edu.gh/wp-content/uploads/2020/04/upsa-favi.png" alt="" height="18">
            </span>
        </a>

        <a href="index.html" class="logo logo-light">
            <span class="logo-sm">
                <img src="https://admissions.upsa.edu.gh/wp-content/uploads/2020/04/upsa-favi.png" alt="" height="22">
            </span>
            <span class="logo-lg">
                <img src="https://admissions.upsa.edu.gh/wp-content/uploads/2020/04/upsa-favi.png" alt="" height="18">
            </span>
        </a>
    </div>

    <button (click)="closeSidebar()" type="button" class="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn">
        <i class="fa fa-fw fa-bars"></i>
    </button>

    <div data-simplebar="init" class="sidebar-menu-scroll bg-white">
        <div class="simplebar-wrapper" style="margin: 0px;">
            <div class="simplebar-height-auto-observer-wrapper">
                <div class="simplebar-height-auto-observer"></div>
            </div>
            <div class="simplebar-mask">
                <div class="simplebar-offset" style="right: -20px; bottom: 0px;">
                    <div class="simplebar-content-wrapper"
                        style="height: 100%; padding-right: 20px; padding-bottom: 0px; overflow: hidden scroll;">
                        <div class="simplebar-content" style="padding: 0px;">

                            <!--- Sidemenu -->
                            <div id="sidebar-menu" class="text-danger font-weight-bold">
                                <!-- Left Menu Start -->
                                <ul class="metismenu list-unstyled mm-show" id="side-menu">


                                    <li class="menu-title">{{'CORE_APPLICATION' | translate}}</li>

                                    <li>
                                        <a routerLink="/app/overview" class="waves-effect" aria-expanded="false"
                                            routerLinkActive=" text-danger font-weight-bold">
                                            <i class="uil-apps"></i>
                                            <span >{{'OVERVIEW' | translate}}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/app/notifications" routerLinkActive="text-danger font-weight-bold">
                                            <i class="uil-bell"></i>
                                            <span>{{'NOTIFICATIONS' | translate}}</span>
                                        </a>
                                    </li>
                                    <br />

                                    <li class="menu-title">{{'FEES_MANAGEMENT' | translate}}</li>

                                    <li>
                                        <a routerLink="/fees/mandatory" routerLinkActive="text-danger font-weight-bold">
                                            <i class="uil-money-bill-stack"></i>
                                            <span>{{'MANDATORY_FEES' | translate}}</span>
                                        </a>
                                    </li>

                                    <!-- <li>
                                        <a routerLink="/fees/non-mandatory" routerLinkActive="text-danger font-weight-bold">
                                            <i class="uil-money-bill-stack"></i>
                                            <span>Non-Mandatory Fees</span>
                                        </a>
                                    </li> -->

                                    <li>
                                        <a routerLink="/fees/requests" routerLinkActive="text-danger font-weight-bold">
                                            <i class="uil-invoice"></i>
                                            <span>{{'REQUESTS' | translate}}</span>
                                        </a>
                                    </li>

                                    <li>
                                        <a routerLink="/fees/payments" routerLinkActive="text-danger font-weight-bold">
                                            <i class="uil-money-bill"></i>
                                            <span>{{'PAYMENTS' | translate}}</span>
                                        </a>
                                    </li>

                                    <!-- <li>
                                        <a routerLink="/fees/submissions" routerLinkActive="text-danger font-weight-bold">
                                            <i class="uil-money-stack"></i>
                                            <span>{{'SUBMISSIONS' | translate}}</span>
                                        </a>
                                    </li> -->

                                    <!-- <li>
                                        <a routerLink="/fees/paid" routerLinkActive="text-danger font-weight-bold">
                                            <i class="uil-money-bill"></i>
                                            <span translate>Paid Fees</span>
                                        </a>
                                    </li> -->

                                    <br />

                                    <li class="menu-title">{{'TRANSACTION_MANAGEMENT' | translate}}</li>

                                    <li>
                                        <a routerLink="/transactions" routerLinkActive="text-danger font-weight-bold">
                                            <i class="uil-list-ul"></i>
                                            <span translate> All Transactions</span>
                                        </a>
                                    </li>
                                    <!-- <li>
                                        <a routerLink="/payments/exports" routerLinkActive="text-danger font-weight-bold">
                                            <i class="uil-file-export"></i>
                                            <span translate>Export Transactions</span>
                                        </a>
                                    </li> -->
                                    <br />

                                    <li class="menu-title" translate>Enquirie</li>

                                    <li>
                                        <a routerLink="/messaging/enquiries" routerLinkActive="text-danger font-weight-bold">
                                            <i class="uil-chat"></i>
                                            <span translate>Send Message</span>
                                        </a>
                                    </li>
                                    <br />

                                    <li class="menu-title" translate>Account</li>

                                    <li>
                                        <a routerLink="/app/settings" routerLinkActive="text-danger font-weight-bold">
                                                <i class="uil uil-cog font-size-16 align-middle mr-1 text-muted"></i> 
                                                <span translate>Settings</span>
                                        </a>
                                    </li>
                                    <br />

                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>