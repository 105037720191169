import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { ApiService } from 'src/app/services/core/api.service';
import { GlobalsService } from 'src/app/services/core/globals.service';
import { UsersService } from 'src/app/services/features/users';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'buy-voucher-form',
  templateUrl: './buy-voucher-form.component.html',
  styleUrls: ['./buy-voucher-form.component.css']
})
export class BuyVoucherFormComponent implements OnInit {
  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	SearchCountry = [SearchCountryField.Iso2, SearchCountryField.Name];
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.Ghana];

  depts: any[] = []; courses: any[] = [];
  submitted : Boolean = false; voucher: any; res: any; 
  @ViewChild('voucherForm') public any: NgForm;
  forms: any[] = [];
  

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: true,
    // timer: 6000,
    // timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  constructor(
    private api: ApiService,
    private _userService: UsersService,
    private _globals: GlobalsService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.init()
    this.getForms()
  }

  init() {
    this.voucher = { name:'', network:'', mobile:'', email:'', amount:'', form: { amount: 0}, formCode: '', schoolCode:environment.schoolCode, currency:environment.currency,  date: new Date()};
  }
  

  getForms() {
    this.api.post('forms', this.voucher).subscribe((data: any) => {
      this.forms = data.data;
      if(this.forms) this.voucher.form = this.forms[0];
    }, (err: HttpErrorResponse) => {
      this.Toast.fire({
        icon: 'error',
        title: `<small> <b> ${err}! <br> unable to proceed </b></small>`,
      })
    }
    );
  }

  buyForm() {
    console.log(this.voucher);
    this.submitted = true;
    this.voucher.mobile = this.voucher.mobile.e164Number;
    this.voucher.formCode = this.voucher.form.code;
    this.voucher.amount = this.voucher.form.amount;
    this.api.post('buy-voucher', this.voucher).subscribe((data:any) =>{
      this.Toast.fire({
        icon: 'success',
        confirmButtonColor: '#dc3545',
        allowOutsideClick : false,
        backdrop: true,
        title: `<small> <b> Payment prompt sent to complete payment. You will receive your voucher code when completed </b></small>`,
      });
      this.router.navigate(['/auth/voucher/verify']);
    },( err: HttpErrorResponse)=>{
      this.submitted = true;
      this.Toast.fire({
        icon: 'error',
        title: `<small> <b> ${err}! <br> unable to proceed </b></small>`,
      })
    });
  }

}






















  // getForm(val) {
  //   this.api.get('form' + val).subscribe((data:any)=>{
  //     this.form = data;
  //     this.type.Amount = data.amount;
  //     this.type.description = data.description;
  //     console.log(data);
  //   })
  // }