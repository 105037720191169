import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/core/api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SigninService } from 'src/app/services/features/signin.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile-update',
  templateUrl: './profile-update.component.html',
  styleUrls: ['./profile-update.component.css']
})
export class ProfileUpdateComponent implements OnInit {

  profile: any[] = [];
  submitted: Boolean = false;
  form!: FormGroup;
  id!: string;
  res: any;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.form = new FormGroup({
      title: new FormControl('', [Validators.required]),
      fullName: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      dob: new FormControl('', Validators.required),
      pob: new FormControl('', Validators.required),
      nationality: new FormControl('', Validators.required),
      maritalStatus: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      hostel: new FormControl(''),
      disability: new FormControl(''),
      explanation: new FormControl(''),
    });
  }

  getProfile() {
    this.api.get('profile').subscribe((data: any) => {
      this.profile = data; 
    }, (err: HttpErrorResponse) => {
      console.log(err);
    });
  }

  get f() {
    return this.form.controls;
  }

  putProfile() {
    console.log(this.form.value)
    this.api.put('profile/' + this.id, this.form.value).subscribe((data: any) => {
      this.res = data;
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'account created',
        showConfirmButton: false,
        timer: 1500
      })
    }, (err: HttpErrorResponse) => {
      this.Toast.fire({
        icon: 'error',
        title: 'Unable to create account',
      })
    });
  }

}
