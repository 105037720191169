import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/core/api.service';
import { SigninService } from 'src/app/services/features/signin.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  active: any; res: any; 
  @Input() title: string = ''

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  
  constructor(private api: SigninService, private _api: ApiService, private router:Router, ) { }

  ngOnInit(): void {
    this.active = this._api.activeuser();
    $('.navbar-header').css('background-color', environment.schoolConfig.colorcode)
    $('body .navbar-header .title').html(environment.schoolConfig.abbr + " |");
    $('body .navbar-header .title').addClass('text-white');
    $('body .navbar-header .subtitle').addClass('text-white');
  }

  openSidebar() {
    $('body').removeClass('sidebar-enable vertical-collpsed');
  }

  logOut() {
    this.api.logOut(this.active.unique_name).subscribe((data: any) => {
      this.res = data; localStorage.removeItem('Token');
      this.Toast.fire({
        icon: 'success',
        title: `<small> <b> You hav been Logged out </b></small>`,
      })
      this.router.navigate(['/auth/login']);
    }, (err: HttpErrorResponse) => {;
      localStorage.removeItem('Token');
      this.router.navigate(['/auth/login']);
    });
  }

}
