import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
    base_url = environment.apiUrl;
//   private uploadUrl = 'files/upload'; // Replace with your API endpoint

  constructor(private http: HttpClient) { }

  uploadFile(url: string, file: File ): Observable<any> {
    // const formData: FormData = new FormData();
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('schoolCode', environment.schoolCode);
    const theurl = this.base_url + url
    return this.http.post(theurl, formData, {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data'
      })
    });
  }
}