    
    <ul class="message-list" *ngIf="notifications.length !== 0">
        <li data-bs-toggle="offcanvas" (click)="click(n)" data-bs-target="#notificationInfo" *ngFor="let n of notifications | paginate  : { itemsPerPage: tableSize, currentPage: page, totalItems: count };">
            <div class="col-mail col-mail-1">
                <!-- <div class="checkbox-wrapper-mail">
                    <input type="checkbox" id="chk19">
                    <label for="chk19" class="toggle"></label>
                </div> -->
                <a class="title fw-bold">{{n?.title}}</a><span class="star-toggle fas fa-star text-warning"></span>
            </div>
            <div class="col-mail col-mail-2">
                <a class="subject"><span>{{n?.description}}...</span>
                </a>
                <div class="date">{{n?.dueDate|date}}</div>
            </div>
        </li>

    </ul>

    
    <section align="left" *ngIf="notifications.length == 0">
        <h3 class="font-weight-bold font-size-18 text-danger" translate> {{'No notification information to render' | translate}}</h3>
        <p translate> {{'No notifications available right now to display' | translate}}</p>
    </section>
    
    <div class="border-0 p-0 m-0">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)" class="text-center"> </pagination-controls>
      </div>

    <notification-info [notification]="notifyInfo"></notification-info>
























            
            <!-- <pagination></pagination> -->