import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CodeInputModule } from 'angular-code-input';

import { HeaderComponent } from './ui/header/header.component';
import { FooterComponent } from './ui/footer/footer.component';
import { LoadingComponent } from './ui/loading/loading.component';
import { NavbarComponent } from './ui/navbar/navbar.component';
import { BreadcrumbComponent } from './ui/breadcrumb/breadcrumb.component';

import { SidebarLayoutComponent } from './layouts/sidebar-layout/sidebar-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { TopbarLayoutComponent } from './layouts/topbar-layout/topbar-layout.component';

import { ErrorComponent } from './ui/error/error.component';
import { NoContentComponent } from './ui/no-content/no-content.component';
import { PaginationComponent } from './ui/pagination/pagination.component';

import { LoginFormComponent } from './forms/login-form/login-form.component';
import { RegisterFormComponent } from './forms/register-form/register-form.component';
import { VoucherFormComponent } from './forms/voucher-form/voucher-form.component';
import { ProfileFormComponent } from './forms/profile-form/profile-form.component';
import { TransactionFilterFormComponent } from './forms/transaction-filter-form/transaction-filter-form.component';
import { StatementFilterFormComponent } from './forms/statement-filter-form/statement-filter-form.component';
import { PasswordFormComponent } from './forms/password-form/password-form.component';
import { AdmissionFormComponent } from './forms/admission-form/admission-form.component';
import { BuyVoucherFormComponent } from './forms/buy-voucher-form/buy-voucher-form.component';
import { EnrollFormComponent } from './forms/enroll-form/enroll-form.component';
import { SetpinFormComponent } from './forms/setpin-form/setpin-form.component';
import { GetstartedFormComponent } from './forms/getstarted-form/getstarted-form.component';
import { OtpFormComponent } from './forms/otp-form/otp-form.component';

import { TransactionListsComponent } from './transactions/transaction-lists/transaction-lists.component';
import { TransactionInfoComponent } from './transactions/transaction-info/transaction-info.component';
import { MandatoryListsComponent } from './transactions/mandatory-lists/mandatory-lists.component';
import { CalculateListsComponent } from './transactions/calculate-lists/calculate-lists.component';
import { RequestListsComponent } from './transactions/request-lists/request-lists.component';
import { PaymentListsComponent } from './transactions/payment-lists/payment-lists.component';
import { SubmissionListsComponent } from './transactions/submission-lists/submission-lists.component';

import { NotificationListsComponent } from './notifications/notification-lists/notification-lists.component';
import { NotificationInfoComponent } from './notifications/notification-info/notification-info.component';

import { WalletListsComponent } from './wallet/wallet-lists/wallet-lists.component';

import { ProfilePreviewComponent } from './profile/profile-preview/profile-preview.component';
import { AdmissionPreviewComponent } from './admission/admission-preview/admission-preview.component';

import {TranslateModule } from '@ngx-translate/core';
import { PaymentFormComponent } from './forms/payment-form/payment-form.component';
import { FundsFormComponent } from './forms/funds-form/funds-form.component';
import { MakePaymentComponent } from './forms/make-payment/make-payment.component';
import { ProfileUpdateComponent } from './forms/profile-update/profile-update.component';
import { AdmissionUpdateComponent } from './forms/admission-update/admission-update.component';
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ArchwizardModule } from 'angular-archwizard';
import { NgSelectModule } from '@ng-select/ng-select';
import { VerifyApplicationComponent } from './forms/verify-application/verify-application.component';
import { NonMandatoryListComponent } from './transactions/non-mandatory-list/non-mandatory-list.component';
import { MessagesComponent } from '../pages/messaging/messages/messages.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxPaginationModule } from 'ngx-pagination';


export const component: Array<any> = [
	HeaderComponent, FooterComponent,
	SidebarLayoutComponent, AuthLayoutComponent, TopbarLayoutComponent,
	BreadcrumbComponent, LoadingComponent, NoContentComponent, NavbarComponent, ErrorComponent, PaginationComponent,

	LoginFormComponent, RegisterFormComponent, VoucherFormComponent, TransactionFilterFormComponent, StatementFilterFormComponent, 
	ProfileFormComponent, ProfileUpdateComponent, AdmissionFormComponent, AdmissionUpdateComponent, PasswordFormComponent, BuyVoucherFormComponent, EnrollFormComponent,
	SetpinFormComponent, GetstartedFormComponent, OtpFormComponent, VerifyApplicationComponent,
	
	TransactionListsComponent, TransactionInfoComponent, MandatoryListsComponent, NonMandatoryListComponent, CalculateListsComponent, RequestListsComponent, PaymentListsComponent, SubmissionListsComponent,
	NotificationListsComponent, NotificationInfoComponent, PaymentFormComponent, FundsFormComponent, MakePaymentComponent,
	WalletListsComponent, 
	ProfilePreviewComponent, AdmissionPreviewComponent,

]

@NgModule({
	declarations: component,
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		NgxSpinnerModule,
		ReactiveFormsModule,
		NgxIntlTelInputModule,
		TranslateModule,
		ArchwizardModule,
		NgSelectModule,
		NgxPaginationModule,
		CodeInputModule.forRoot({
			codeLength: 6,
			isCharsCode: false
		}),
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	exports: component
})
export class ComponentsModule {}
