import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/core/api.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'otp-form',
  templateUrl: './otp-form.component.html',
  styleUrls: ['./otp-form.component.css']
})
export class OtpFormComponent implements OnInit {
  
  submitted : Boolean = false; code: any; res: any; 
  @ViewChild('otpForm') public any: NgForm;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(private api: ApiService, private router:Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.init();
    this.route.queryParams.subscribe(data=>{
      console.log(data);
      this.code.mobile = data.indexnumber;
    })
  }

  init() {
    this.code = { otp: '', schoolCode:environment.schoolCode, date: new Date()};
  }

  verifyOtp() {
    this.submitted = true;
    console.log(this.code)
    this.api.post('verify-otp', this.code).subscribe((data: any) => {
      this.Toast.fire({
        icon: 'success',
        // title: 'number verified',
        title: `<small> <b> Number verified </b></small>`,
      })
    this.router.navigate(['/auth/setpin']);
    }, (err: HttpErrorResponse) => {
      this.submitted = false;
      this.Toast.fire({
        icon: 'error',
        title: `<small> <b> ${err}! </b></small>`,
      })
    });
  }

}
