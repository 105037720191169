
          <form [formGroup]="form" (ngSubmit)="putProfile()">
            <div class="mb-3 form-group">
                <label class="control-label dark" for="title" translate>Title *</label>
                <select class="form-control select2-search-disable select2-hidden-accessible" formControlName="title" aria-hidden="true">
                  <option value="">Choose title</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                </select>
            </div>

            <div class="mb-3 form-group">
                <label class="control-label dark" for="fullName" translate>Full Name *</label>
                <input type="text" class="form-control" formControlName="fullName" placeholder="{{'Full Name ' | translate }}" required>
            </div>
  
            <div class="mb-3 form-group">
                <label class="control-label dark" for="phoneNumber" translate>Phone Number *</label>
                <input type="number" class="form-control" formControlName="phoneNumber" placeholder="{{'Phone Number' | translate }}" required>
            </div>
  
            <div class="form-group">
              <label class="control-label dark" for="email" translate>Email *</label>
              <input class="form-control" type="email" formControlName="email" placeholder="{{'Email' | translate }}" required/>
            </div>

            <div class="mb-3 form-group">
                <label class="control-label dark" for="dob" translate>Date of Birth *</label>
                <input type="date" class="form-control" formControlName="dob" placeholder="{{'DOB' | translate }}" required>
            </div>
  
            <div class="form-group">
              <label class="control-label dark" for="pob" translate>Place of Birth *</label>
              <input class="form-control" type="text" formControlName="pob" placeholder="{{'PLACE_OF_BIRTH' | translate }}" required/>
            </div>

            <div class="mb-3 form-group">
                <label class="control-label dark" for="nationality" translate>Nationality *</label>
                <select class="form-control select2-search-disable select2-hidden-accessible" formControlName="nationality" aria-hidden="true" required>
                  <option value="">Choose a country</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Nigeria">Nigeria</option>
                </select>
            </div>

            <div class="mb-3 form-group">
                <label class="control-label dark" for="validationCustom01" translate>Marital Status *</label>
                <select class="form-control select2-search-disable select2-hidden-accessible" formControlName="maritalStatus" aria-hidden="true" required>
                  <option value="">Choose your relationship status</option>
                  <option value="Married">Married</option>
                  <option value="Single">Single</option>
                </select>
            </div>
  
            <div class="mb-3 form-group">
                <label class="control-label dark" for="address">{{'ADDRESS' | translate }} *</label>
                <textarea class="form-control" formControlName="address" rows="3" required></textarea>
            </div>

            <div>
              <span class="dark">Hostel Required ?</span>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="hostel" formControlName="hostel" id="exampleRadiosA" value="yes" checked>
                <label class="form-check-label" for="exampleRadiosA">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="hostel" formControlName="hostel" id="exampleRadiosB" value="no">
                <label class="form-check-label" for="exampleRadiosB">
                  No
                </label>
              </div>
            </div>
             <br>
  
            <div>
              <span class="dark">Disability that require special attention ?</span>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="disability" formControlName="disability" id="exampleRadios1" value="yes" checked>
                <label class="form-check-label" for="exampleRadios1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="disability" formControlName="disability" id="exampleRadios2" value="no">
                <label class="form-check-label" for="exampleRadios2">
                  No
                </label>
              </div>
            </div>
             <br>
  
            <div class="mb-3 form-group">
                <label class="control-label dark" for="explanation">{{'IF_YES' | translate }}</label>
                <input type="text" class="form-control" formControlName="explanation">
            </div>
            <button type="submit" [disabled]="!form.valid" class="btn btn-dark">Save</button>
            <br />
          </form>