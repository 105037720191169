import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/core/api.service';
import { GlobalsService } from 'src/app/services/core/globals.service';
import { UsersService } from 'src/app/services/features/users';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'verify-voucher-form',
  templateUrl: './voucher-form.component.html',
  styleUrls: ['./voucher-form.component.css']
})
export class VoucherFormComponent implements OnInit {

  submitted : Boolean = false; vCode: any; res: any; 
  @ViewChild('voucherForm') public any: NgForm;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  
  constructor(
    private _userService: UsersService,
    private api: ApiService,
    private _globals: GlobalsService,
    private router:Router
  ) {
  }

  ngOnInit(): void {
   this.init()
  }

  init() {
    this.vCode = { voucher_code: '', schoolCode:environment.schoolCode, date: new Date()};
  }

  verifyVoucher() {
    this.submitted = true;
    console.log(this.vCode)
    this.api.post('verify-voucher', this.vCode).subscribe((data: any) => {
       this.Toast.fire({
        icon: 'success',
        title: `<small> <b> Voucher verified </b></small>`,
      });
      const params = { voucher_code: this.vCode.voucher_code }
      this.router.navigate(['/auth/register'], { queryParams: params });
      // this.router.navigate(['/auth/register']);
    }, (err: HttpErrorResponse) => {
      this.submitted = false;
      this.Toast.fire({
        icon: 'error',
        title: `<small> <b> ${err} </b></small>`,
      });
    });
  }
}
