import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/core/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'submission-lists',
  templateUrl: './submission-lists.component.html',
  styleUrls: ['./submission-lists.component.css']
})
export class SubmissionListsComponent implements OnInit {

  response: any;
  submissions: any[] = [];
  search: string = '';

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(public api: ApiService) {}

  ngOnInit() {
    this.getAll();
  }

  // Get  list
  getAll() {
    this.api.post('student/applications/submitted?page=1&limit=10', []).subscribe((data: any) => {
      this.response = data;
      this.submissions = data.data;
      this.Toast.fire({
        icon: 'success',
        title: `<small> <b>Submission list fetched</small>`,
      })
    }, (err: HttpErrorResponse) => {
      this.Toast.fire({
        icon: 'error',
        title: `<small> <b> ${err} </b></small>`,
      })
    });
  }


  getMore(page: number = 1, limit: number = 10) {
    this.api.post('student/applications/submitted?search=' + this.search + '&page=' + page + '&limit=' + limit, []).subscribe((data: any) => {
      this.response = data;
      this.submissions = data.data;
      console.log(data);
    });
  }

}
