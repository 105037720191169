
<div id="layout-wrapper">
    <app-header [title]="_globals?.title[1] + ' ' + _globals?.title[2]"></app-header>
    <app-navbar></app-navbar>

    <div class="main-content">
        <div class="page-content">
            <div class="container-fluid no-padding">
                <app-breadcrumb *ngIf="_globals?.title[2] != 'settings'" [title]="_globals?.title[2] == 'overview' ? _globals?.title[2] : _globals?.title[2]"></app-breadcrumb>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>