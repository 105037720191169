import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError } from '@angular/router';
import { ApiService } from 'src/app/services/core/api.service';
import { GlobalsService } from 'src/app/services/core/globals.service';
import { environment } from 'src/environments/environment';
declare let $: any;

@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.css']
})
export class SidebarLayoutComponent implements OnInit {

  constructor(
    public _globals: GlobalsService, private api: ApiService
  ) { 
    this._globals.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) this._globals.title  = event.url.split('/');
        if (event instanceof NavigationError) {
          console.log(event.error);
        }
    }); this.wallet();
}

  ngOnInit(): void {
    $('.navbar-brand-box img').attr('src',  environment.schoolConfig.logo)
  }
  
  wallet() {
    this.api.get('student/wallet/').subscribe((data:any) => {
      this.api.wallet = data.data[0];
    }, (err: HttpErrorResponse) => {
      this.api.error(err);
    });
  }

}
