import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/core/api.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-verify-application',
  templateUrl: './verify-application.component.html',
  styleUrls: ['./verify-application.component.css']
})
export class VerifyApplicationComponent implements OnInit {

  
  submitted : Boolean = false; app: any; res: any; 
  @ViewChild('appForm') public any: NgForm;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(private api: ApiService, private router:Router) { }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.app = { voucherCode: '', schoolCode:environment.schoolCode, date: new Date()};
  }

  validateCode() {
    this.submitted = true;
    console.log(this.app)
    this.api.post('application-details', this.app).subscribe((data: any) => {
      const params = { voucherCode: this.app.voucherCode }
      this.router.navigate(['/admissions/application'], { queryParams: params });
    }, (err: HttpErrorResponse) => {
      this.submitted = false;
      this.Toast.fire({
        icon: 'error',
        title: `<small> <b> ${err} </b> </small>`,
      })
    });
  }

}
