import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/core/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'notification-info',
  templateUrl: './notification-info.component.html',
  styleUrls: ['./notification-info.component.css']
})
export class NotificationInfoComponent implements OnInit {
  @Input() notification: any; id: string;
  
  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(private api: ApiService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    // this.notification(this.route.snapshot.paramMap.get('id'));
    // this.notification = this.route.snapshot.paramMap.get('id');
  }

  // getNotification(id: string) {
  //   this.api.get('notifications/' + id).subscribe((data: any) => {
  //     this.notification = data;
  //   }, (err: HttpErrorResponse) => {
  //     this.Toast.fire({
  //       icon: 'error',
  //       title: 'error fetching detail',
  //     })
  //   });
  // }


}
