import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError } from '@angular/router';
import { GlobalsService } from 'src/app/services/core/globals.service';
declare let $: any;

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.css']
})
export class AuthLayoutComponent implements OnInit {

  constructor(
    public _globals: GlobalsService
  ) { 
    this._globals.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this._globals.title  = event.url.split('/')[2];
      
          if(this._globals.title == 'register') { $('.card').css('margin-top', '-120px') }
          if(event.url.split('/')[3] == 'buy') { $('.card').css('margin-top', '-50px') }
          if(this._globals.title != 'register' && event.url.split('/')[3] != 'buy') $('.card').css('margin-top', '0px')
        }
        if (event instanceof NavigationError) {
          console.log(event.error);
        }
    }); 
  }

  ngOnInit(): void {
    $('body').attr('data-layout', 'veritcal')
    $('body').attr('data-topbar', 'dark')
    $('body').removeClass('vertical-collpsed')
  }

}
