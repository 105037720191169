<button style="width: 100%" [disabled]="bills.length == 0" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" class="btn btn-dark "> <span>Make payment </span> </button>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <h5 id="offcanvasRightLabel">Make Payment</h5>
        <button type="button" class="btn-close text-reset" id="close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
        <p class="mt-4 text-left dark bold fs-12 mb-1">Wallet Balance:</p>
        <h5 class="text-left">{{api?.wallet?.balance | currency:api?.wallet?.currency+' '}}</h5>
        <div class="alert alert-info text-left fw-bold" role="alert">
            Amount to pay can be changed before paying!
        </div>
        <div class="alert alert-warning text-left fw-bold" role="alert" *ngIf="total > api?.wallet?.balance">
           Make sure you have sufficient amount before proceeding!
        </div>
        <!-- {{bills | json }} -->
        <table class="table table-centered datatable dt-responsive nowrap table-card-list table-check dataTable no-footer dtr-inline text-left"
        style="border-collapse: collapse; border-spacing: 0px 8px; width: 100%;"
        id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
            <thead>
                <tr class="bg-transparent" role="row">
                    <th class="sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                        colspan="1" style="width: 148px;" aria-sort="ascending"
                        aria-label="Bill Name: activate to sort column descending">Bill Name</th>
                    <!-- <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                        colspan="1" style="width: 137px;"
                        aria-label="Date: activate to sort column ascending">Amount</th> -->
                    <!-- <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                        colspan="1" style="width: 110px;"
                        aria-label="Status: activate to sort column ascending">Qty</th> -->
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                        colspan="1" style="width: 80px;"
                        aria-label="amount_payable: activate to sort column ascending">Payable Amount</th>
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                        colspan="1" style="width: 80px;"
                        aria-label="Amount: activate to sort column ascending">Amount to pay</th>
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                        colspan="1" style="width: 60px;"
                        aria-label="Amount: activate to sort column ascending">Balance</th>
                </tr>
            </thead>
            <tbody>
                <tr role="row" class="odd" *ngFor="let b of bills; let i=index;">
                    <td class="middle">{{b?.fee}} </td>
                    <!-- <td class="center middle"> {{b?.amount | currency:currency+' '}}</td> -->
                    <td class="middle"> {{b?.amount_payable | currency:b?.currency+' '}}</td>
                    <!-- <td>GHS</td> -->
                    <td class="middle"> 
                         <input class="form-control" (keyup)="change(i,'amount',$event)" (blur)="update(i,'amount',$event)" [(ngModel)]="b.amount" autofocus type="number" min="1" />
                    </td>
                    <td class="middle">{{(b?.amount_payable - b?.amount) | currency:b?.currency+' '}}</td>
                </tr>
            </tbody>
        </table>
        <p class="mt-4 dark bold fs-12 mb-1 text-right">Total Bill</p>
        <h5 class="text-right">{{total | currency:currency+' '}}</h5>
        <div class="d-flex justify-content-end mt-5">
            <div class="col-md-4 pull-right">
                <button (click)="payBills()" [disabled]="(total === null || 1 > total) || total > api?.wallet?.balance" class="btn btn-dark btn-block">Pay Bill</button>
            </div>
            <!-- <div class="col-md-4">
                <a class="btn btn-dark btn-block">Generate Bill</a>
            </div>
            <div class="col-md-4">
                <a class="btn btn-danger btn-block">Cancel Bill</a>
            </div> -->
        </div>
    </div>
</div>
