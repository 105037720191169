
<div class="splash" *ngIf="showsplash">
    <!-- <div align="center" class="animated fadeIn">
      <img src="assets/img/loader.gif" class="animated pulse infinite" />
    </div> -->
    <h3 class="fs-12 bottom text-muted">Powered By <br /><span class="fs-20 dark">Cross Switch Ghana</span></h3>
    <br />
</div>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="default" color="#fff" type="ball-clip-rotate"></ngx-spinner>
<router-outlet></router-outlet>