import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/core/api.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mandatory-lists',
  templateUrl: './mandatory-lists.component.html',
  styleUrls: ['./mandatory-lists.component.css']
})
export class MandatoryListsComponent implements OnInit {

  response: any;
  mandatory: any[] = [];
  loading: boolean = true;
  lists: any[] = [];
  codes: any[] = [];
  search: string = ''; total = 0;
  currency = environment.currency
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;

  constructor(public api: ApiService) { }

  ngOnInit(): void {
    this.getAll();
    this.lists = [];
    this.codes = [];
  }

  // Get  list
  getAll() {
    this.api.post('student/fees/mandatory?page=1&limit=10', []).subscribe((data: any) => {
      this.response = data;
      this.mandatory = data.data;
      this.api.Toast.fire({
        icon: 'success',
        title: `<small><b> Fetched Mandatory Fees </b> </small>`,
      })
    }, (err: HttpErrorResponse) => {
      this.loading = false;
      this.api.error(err);
    });
  }

  getMore(page: number = 1, limit: number = 10) {
    this.api.get('student/fees/mandatory?search=' + this.search + '&page=' + page + '&limit=' + limit).subscribe((data: any) => {
      this.response = data;
      this.mandatory = data.data;
      console.log(data);
    });
  }

  Selected(val){
    this.codes.push(val.code);
    var bill = { code: val.code, fee: val.feeType_Name, bill_amount: val.amount, 
        amount_payable: val.balance_Payable, amount: val.balance_Payable, currency: val.currency };
    this.lists.push(bill)
    this.total += (val.balance_Payable);
  }

  RemoveSelected(val){
    this.codes = this.codes.filter((c)=> c != val.code);
    this.lists = this.lists.filter((c)=> c.code != val.code);
    this.total -= (val.balance_Payable);
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getAll();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getAll();
  }

}
