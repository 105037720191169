import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { SigninService } from 'src/app/services/features/signin.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})

export class LoginFormComponent implements OnInit {
  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	SearchCountry = [SearchCountryField.Iso2, SearchCountryField.Name];
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.Ghana];

  submitted : Boolean = false; log: any; isLoginError: boolean; res: any; returnUrl: string;
  @ViewChild('loginForm') public any: NgForm;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(private api: SigninService, private router: Router, private route: ActivatedRoute, ) { }
  

  ngOnInit() {
    this.init();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/app/overview';
    // console.log(this.returnUrl)
  }


  init() {
    this.log = {pin: '', indexNumber: '', schoolCode:environment.schoolCode,  date: new Date()};
  }

  login() {
    this.submitted = true;
    this.log.indexNumber = this.log.mobile.e164Number;
    this.api.loginUser(this.log).subscribe((data: any) => {
      if  (data && data.access_token) {
        localStorage.setItem('Token', JSON.stringify(data.access_token));
        localStorage.setItem('Date', JSON.stringify(data.date));
        this.Toast.fire({
          icon: 'success',
          title: `<small> <b> Login successful </b></small>`,
        }),
        window.location.href = this.returnUrl;
      }
    }, (err: HttpErrorResponse) => {
      this.submitted = false;
      this.Toast.fire({
        icon: 'error',
        title: 'Unable to login',
      }),
      this.Toast.fire({
        icon: 'error',
        title: `<small> <b> ${err} </b></small>`,
      })
    });
 }

}
