import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/core/api.service';

@Component({
  selector: 'statement-filter-form',
  templateUrl: './statement-filter-form.component.html',
  styleUrls: ['./statement-filter-form.component.css']
})
export class StatementFilterFormComponent implements OnInit {

  response: any;
  filter: any = [];

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.getAll();
  }

  // Get  list
  getAll() {
    this.api.get('statement?page=1&limit=8').subscribe((data: any) => {
      this.response = data;
      this.filter = data.data;
    });
  }

  
  Filter() {
    this.api.post('statement', this.filter).subscribe((data:any) =>{
    },( err: HttpErrorResponse)=>{
      // console.log(err);
    });
  }

}
