import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
declare let $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  {
  showsplash: boolean = true
  title = 'admin';

  ngOnInit() {
    // this.recallJsFuntions();

    setTimeout(() => {
      this.showsplash = false
    }, 1000)
    
  }

   // public img = config.imageUrl;
   constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }
  


  recallJsFuntions() {
    // $('body').addClass('preloader-site');

    // $(window).load(function() {
    //     $('.preloader-wrapper').delay(1000).fadeOut("slow");
    //     $('body').removeClass('preloader-site');
    //     // $('.footable').footable();
    // });
  }
}




