import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title: any = ''
  @Input() subtitle: string = ''
  name: string = environment.schoolConfig.name
  constructor(
  ) { }

  ngOnInit(): void {
  }

}
