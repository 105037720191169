import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/core/api.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-non-mandatory-list',
  templateUrl: './non-mandatory-list.component.html',
  styleUrls: ['./non-mandatory-list.component.css']
})
export class NonMandatoryListComponent implements OnInit {

  response: any;
  mandatory: any[] = [];
  lists: any[] = [];
  codes: any[] = [];
  search: string = ''; total = 0;
  currency = environment.currency;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(public api: ApiService) { }

  ngOnInit(): void {
    this.getAll();
  }

  // Get  list
  getAll() {
    this.api.post('student/fees/non-mandatory?page=1&limit=10', []).subscribe((data: any) => {
      this.response = data;
      this.mandatory = data.data;
      this.Toast.fire({
        icon: 'success',
        title: `<small><b>Fetched Fees Types </b> </small>`,
      })
    }, (err: HttpErrorResponse) => {
      this.Toast.fire({
        icon: 'error',
        // title: 'unable to fetch list',
        title: `<small> <b> ${err}  </b></small>`,
      })
    });
  }

  getMore(page: number = 1, limit: number = 10) {
    this.api.get('student/fees/non-mandatory?search=' + this.search + '&page=' + page + '&limit=' + limit).subscribe((data: any) => {
      this.response = data;
      this.mandatory = data.data;
      console.log(data);
    });
  }
  
  Selected(val){
    this.codes.push(val.code);
    this.lists.push(val)
    this.total += (val.balance_Payable);
  }

  RemoveSelected(val){
    this.codes = this.codes.filter((c)=> c != val.code);
    this.lists = this.lists.filter((c)=> c.code != val.code);
    this.total -= (val.balance_Payable);
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getAll();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getAll();
  }

}
