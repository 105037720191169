import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/core/api.service';
import { GlobalsService } from 'src/app/services/core/globals.service';
import { UsersService } from 'src/app/services/features/users';
import Swal from 'sweetalert2';

@Component({
  selector: 'admission-form',
  templateUrl: './admission-form.component.html',
  styleUrls: ['./admission-form.component.css']
})
export class AdmissionFormComponent implements OnInit {

  arr: any = [0]
  submitted : Boolean = false; ad: any; res: any; 
  @ViewChild('adForm') public any: NgForm;
  id: number;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(private api: ApiService, private _userService: UsersService, private _globals: GlobalsService) { }

  ngOnInit(): void {
    this.init()
  }

  init() {
    this.ad = { certificate: '', dateAwarded: '', grade: '', subject: '', date: new Date()};
  }

  addNewForm() {
    this.arr.push(this.arr.length + 1)
  }

  admission() {
    console.log(this.ad)
    // this.api.post('admission', this.newAdmissionForm).subscribe((data:any) =>{
      this.api.put('admission/' + this.ad.id, this.ad).subscribe((data: any) => {
        this.res = data;
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'profile updated',
          showConfirmButton: false,
          timer: 1500
        });
        this.ad = '';
    },( err: HttpErrorResponse)=>{
      console.log(err)
      this.Toast.fire({
        icon: 'error',
        title: 'unable to update profile',
      })
    });
  }

}

