<form class="custom-validation">
    <div class="mb-3 mt-3">
        <label class="form-label">{{'WALLET_INFO' | translate }}</label>
        <select class="form-control select2-search-disable select2-hidden-accessible" aria-hidden="true">
            <option value="">{{'CHOOSE_WALLET' | translate }}</option>
            <option>{{'DEFAULT_WALLET' | translate }}</option>
        </select>
    </div>


    <div class="form-inline mb-3">
        <label class="form-label">{{'FROM_DATE' | translate }} / {{'TO_DATE' | translate }}</label>
        <div class="input-daterange input-group" data-provide="datepicker" data-date-format="dd M, yyyy" data-date-autoclose="true">
            <input type="text" class="form-control text-left" placeholder="From" name="From">
            <input type="text" class="form-control text-left" placeholder="To" name="To">
            <div class="input-group-append">
                <button type="button" class="btn btn-primary"><i class="mdi mdi-filter-variant"></i></button>
            </div>
        </div>
    </div>
    <button type="button" class="btn btn-outline-dark waves-effect waves-light mb-3 mt-1">{{'FILTER_TRANSACTIONS' | translate }}</button>
</form>
<hr />
<div class="mt-3">
    <h4 class="card-title">{{'FILTER_CONFIG' | translate }}</h4>
    <p class="card-title-desc">{{'CONFIRM_VALIDATE' | translate }}</p>
    <br />
    <b class="text-muted">{{'WALLET_INFO' | translate }}</b>
    <h3 class="font-size-16 text-muted">{{'DEFAULT_WALLET' | translate }}</h3><br >

    <b class="text-muted">{{'FROM' | translate }}</b>
    <h3 class="font-size-16 text-muted">24 Febuary, 2022</h3><br >

    <b class="text-muted">{{'TO' | translate }}</b>
    <h3 class="font-size-16 text-muted">10 August, 2022</h3><br >
</div>