import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/core/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'notification-lists',
  templateUrl: './notification-lists.component.html',
  styleUrls: ['./notification-lists.component.css']
})
export class NotificationListsComponent implements OnInit {
  response: any;
  notifications: any[] = [];
  search: string = '';
  loading: boolean = false; 
  notifyInfo: any;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  
  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(public api: ApiService) { }

  ngOnInit() {
    this.getAll();
  }

  getAll() {
    this.api.get('student/notifications?page=1&limit=8').subscribe((data: any) => {
      this.response = data;
      this.notifications = data.data;
      this.Toast.fire({
        icon: 'success',
        title: `<small> <b> notification list fetched <b> </small>`,
      })
    }, (err: HttpErrorResponse) => {
      this.Toast.fire({
        icon: 'error',
        title: `<small> <b> ${err} <br> unable to fetch notifiactions </br></small>`,
      })
    });
  }

  getMore(page: number = 1, limit: number = 10) {
    this.api.get('notifications?search=' + this.search + '&page=' + page + '&limit=' + limit).subscribe((data: any) => {
      this.response = data;
      this.notifications = data.data;
      console.log(data);
    });
  }

  click(n){
    this.notifyInfo = n;
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.getAll();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getAll();
  }


}
