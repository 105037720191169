

<div class="row mt-2">
    <table
    class="table table-centered datatable dt-responsive nowrap table-card-list table-check dataTable no-footer dtr-inline"
    style="border-collapse: collapse; border-spacing: 0px 8px; width: 100%;"
    id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
        <thead>
            <tr class="bg-transparent" role="row">
                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                    colspan="1" style="width: 137px;"
                    aria-label="Date: activate to sort column ascending">Code</th>
                <th class="sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                    colspan="1" style="width: 148px;" aria-sort="ascending"
                    aria-label="Invoice ID: activate to sort column descending">FeeType</th>
                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                    colspan="1" style="width: 110px;"
                    aria-label="Status: activate to sort column ascending">Qty</th>
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                        colspan="1" style="width: 110px;"
                        aria-label="Status: activate to sort column ascending">Bill Amount</th>
                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                    colspan="1" style="width: 129px;" aria-label="Amount: activate to sort column ascending">Amount Payable</th>
            </tr>
        </thead>
        <tbody>
            <tr role="row" class="odd" *ngFor="let f of fees">
                <td>{{f?.code}}</td>
                <td class="sorting_1"><a href="javascript: void(0);" class="dark font-weight-bold">{{f?.name}}</a> </td>
                <!-- <td>GHS</td> -->
                <td>{{f?.quantity}}</td>
                <td>{{f?.amount | currency:f?.currency+' '}}</td>

                <td>
                    {{f?.amount | currency:f?.currency+' '}}
                </td>
            </tr>
            <tr role="row" class="even" hidden>

                <td class="sorting_1"><a href="javascript: void(0);"
                        class="dark font-weight-bold">Undergraduates & Diploma Graduation Fee</a> </td>
                <td>GHS</td>
                <td>1</td>
                <td>GHS 270.80</td>

                <td>
                    GHS 270.80
                </td>
            </tr>
            <tr role="row" class="even" hidden>

                <td class="sorting_1"><a href="javascript: void(0);"
                        class="dark font-weight-bold">Resit Registration-post Graduate</a> </td>
                <td>GHS</td>
                <td>1</td>
                <td>GHS 270.80</td>

                <td>
                    GHS 270.80
                </td>
            </tr>
        </tbody>
    </table>

    <p class="mt-4 dark bold fs-12 mb-1">Total Bill</p>
    <h5>{{ total | currency: 'GHS '}}</h5>
    <div class="row mt-5">
        <!-- <div class="col-md-2">
            <a class="btn btn-dark btn-block">Pay Bill</a>
        </div> -->
        <div class="col-md-2">
            <button [disabled]="0 >= total" (click)="Request()" class="btn btn-dark btn-block">Generate Bill</button>
        </div>
        <div class="col-md-2">
            <a [routerLink]="['/fees/payments']" class="btn btn-danger btn-block">Cancel Bill</a>
        </div>
    </div>
</div>