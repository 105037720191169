<div id="my-5">
    <div class="h-100">
        <div class="page-content">
            <div class="container-fluid position-absolute top-50 start-50 translate-middle">

                <div class="row center align-items-start justify-content-evenly">
                    <div class="col-md-4">
                        <div class="pb-3 mb-5">
                            <a [routerLink]="['/']" routerLinkActive="router-link-active"> <img class="logo-img" style="width: 200px;"
                                    src="assets/img/logo.png" alt=""></a>
                        </div>
                        <div class="">
                            <h2 class="dark fs-30 fw-bold">School Billing And Payment System</h2><br>
                            <p class="dark">School Billing made easy. Remove friction from managing all <br
                                    class="d-lg-inline d-md-none d-none"> academic payment processes, end-to-end</p><br>

                            <button routerLink="/auth/voucher"
                                class="font-weight-medium btn cs-red shadow-lg px-4 py-2 mb-5 buy-voucher"> Go Back
                                <img src="assets/img/arrow-right.png" alt=""> </button>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="card">
                            <div class="card-body">
                                <form role="form" method="Post" #voucherForm="ngForm" (ngSubmit)="buyForm()">
                                    <h4 class="card-title dark fw-bold">Fill form to buy voucher</h4>
<p class="dark">Need to register into the student portal?.Buy your voucher from our distinguish outlets and stores</p>
                                    <div class="mb-3 form-group">
                                        <label class="col-form-label" for="form" translate>Form *</label>
                                        <div class="input-group">
                                            <ng-select class="input-group w-100" placeholder="Choose form..." aria-hidden="true" name="form"
                                                [(ngModel)]="voucher.form" required>
                                                <ng-option *ngFor="let f of forms" [value]="f"> {{f.name}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                
                                    <div>
                                        <label class="form-label" translate>Amount</label>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">{{voucher.currency}}</span>
                                            </div>
                                            <input style="background-color: #f5f6f8;" [(ngModel)]="voucher.form.amount" name="amount" type="number"
                                                class="form-control" aria-label="Username" aria-describedby="basic-addon1" disabled readonly>
                                        </div>
                                    </div>
                                
                                    <div class="mb-3">
                                        <label class="form-label" translate>Name of Student *</label>
                                        <input [(ngModel)]="voucher.name" name="name" type="text" class="form-control"
                                            placeholder="{{'FULLNAME' | translate}}" required>
                                    </div>
                                
                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label" translate>Wallet *</label>
                                            <select [(ngModel)]="voucher.network" name="network"
                                                class="form-control select2-search-disable select2-hidden-accessible" aria-hidden="true" required>
                                                <option value="">{{'Choose Wallet' | translate}}</option>
                                                <option [value]="'MTN'">MTN Mobile Money</option>
                                                <option [value]="'Vodafone'">Vodafone Cash</option>
                                                <option [value]="'Airtel'">Airtel Tigo Money</option>
                                            </select>
                                        </div>
                                
                                        <div class="wrapper col-md-6">
                                            <label for="mobile">Mobile Number *</label>
                                            <div class="form-group">
                                                <ngx-intl-tel-input [cssClass]="'form-control custom'"
                                                    [preferredCountries]="preferredCountries"
                                                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                                    [searchCountryFlag]="true" [searchCountryField]="SearchCountry"
                                                    [selectFirstCountry]="true"
                                                    [selectedCountryISO]="CountryISO.Ghana"
                                                    [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                                    [numberFormat]="PhoneNumberFormat.National"
                                                    [(ngModel)]="voucher.mobile" name="mobile" required>
                                                </ngx-intl-tel-input>
                                            </div>
                                        </div>
                                
                                        <div class="mb-3">
                                            <label class="form-label" translate>Email *</label>
                                                <div>
                                                    <input class="form-control" type="email" name="email" id="email" placeholder="abc@gmail.com" [(ngModel)]="voucher.email" [ngModelOptions]="{ updateOn: 'blur' }" #emailerror="ngModel" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
                                                        <div *ngIf="emailerror.invalid && (emailerror.dirty || emailerror.touched)">
                                                            <div *ngIf="emailerror.errors.required">
                                                                <div class="alert alert-danger" role="alert">
                                                                    Email is required
                                                                  </div>
                                                            </div>
                                                            <div *ngIf="emailerror.errors.pattern">
                                                                <div class="alert alert-danger" role="alert">
                                                                    Invalid email format! Please check and try again
                                                                  </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                    </div>
                                
                                    <br />
                                    <button [disabled]="!voucherForm.valid" class="btn btn-danger btn-block" type="submit"><span *ngIf="submitted == false"> {{'COMPLETE_ORDER' | translate}}</span>  <span class="disabled" *ngIf="submitted == true">please wait <i class="fas fa-spinner fa-spin"></i> </span> </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
