import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/core/api.service';

@Component({
  selector: 'calculate-lists',
  templateUrl: './calculate-lists.component.html',
  styleUrls: ['./calculate-lists.component.css']
})
export class CalculateListsComponent implements OnInit {
  response: any;
  @Input() fees: any[] = [];
  search: string = '';
  fee: any = [];
  @Input() total = 0;
  @Output() complete = new EventEmitter<any>();

  constructor(public api: ApiService, private route: Router) { }

  ngOnInit() {
    // this.getAll();
  }

  // Get  list
  Request() {
    const data = { walletNumber: this.api.wallet.code, currency: this.api.wallet.currency, amount: this.total, source: "PORTAL", feeTypes: this.fees, makePayment: false }
    this.api.post('student/request-fee-payment', data).subscribe((data: any) => {
      this.response = data;
      this.fees = data.data;
      this.api.success("Fees Request was successful");
      this.complete.emit(data);
      document.getElementById('close').click();
      this.route.navigate(['/fees/payments'])
    });
  }

  PayBill() {
  }

  getMore(page: number = 1, limit: number = 10) {
    // console.info(this.searchTitle);
    this.api.get('fees?search=' + this.search + '&page=' + page + '&limit=' + limit).subscribe((data: any) => {
      this.response = data;
      this.fees = data.data;
      console.log(data);
    });
  }
  
  
  update(id:number, property:string, event:any) {
    const editField = parseInt(event.target.textContent);
    console.log(editField)
    if(editField > 0) this.fees[id][property] = editField;
    this.getTotal();
  }

  change(id:number, property:string, event:any){
    const editField = parseInt(event.target.textContent);
    console.log(editField, 'change');
    if(editField > 0) this.fees[id][property] = editField;
    this.getTotal();
  }

  getTotal() {
    this.total = this.fees.reduce((amount,feeTypes)=>{
      return parseInt(amount) + parseInt(feeTypes.amount);
    },0);
  }

}
