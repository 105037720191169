
<div class="row mt-3">
    <div class="">
        <div class="row">
          <div class="col">
            <p class="mt-4 text-left dark bold fs-12 mb-1">Wallet Balance:</p>
            <h5 class="text-left">{{api?.wallet?.balance | currency:api?.wallet?.currency+' '}}</h5>
          </div>
          <div class="col-md-auto">
            <p class="dark bold fs-12 mb-1" translate>Total Selected Bill Amount</p>
            <h5>{{total|currency:currency+' '}}</h5>
          </div>
          <div class="col col-lg-2">
            <app-payment-form [total]="total" [bills]="lists" (complete)="ngOnInit()"></app-payment-form>
          </div>
        </div>
      </div>

    <div class="col-lg-12">
        <div class="table-responsive" *ngIf="bills.length > 0">
            <table
            class="table table-centered datatable dt-responsive nowrap table-card-list table-check dataTable no-footer dtr-inline"
            style="border-collapse: collapse; border-spacing: 0px 8px; width: 100%;"
            id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
            <thead>
                <tr class="bg-transparent" role="row">
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                        colspan="1" style="width: 80px;"
                        aria-label="Date: activate to sort column ascending" translate>Code</th>
                    <th class="sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                        colspan="1" style="width: 200px;" aria-sort="ascending"
                        aria-label="Invoice ID: activate to sort column descending" translate>Description</th>
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                        colspan="1" style="width: 201px;"
                        aria-label="Billing Name: activate to sort column ascending" translate>Bill Amount</th>
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                        colspan="1" style="width: 110px;"
                        aria-label="Status: activate to sort column ascending" translate>Amount Paid</th>
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                        colspan="1" style="width: 100px;"
                        aria-label="Download Pdf: activate to sort column ascending" translate>Status</th>
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                        colspan="1" style="width: 100px;"
                        aria-label="Status: activate to sort column ascending" translate>Current Payable</th>
                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                        colspan="1" style="width: 100px;"
                        aria-label="Download Pdf: activate to sort column ascending" translate>Action</th>
                </tr>
            </thead>
                <tbody>
                    <tr *ngFor="let m of bills | paginate  : { itemsPerPage: tableSize, currentPage: page, totalItems: count }; let i = index" role="row" class="odd">
                        
                        <td class="middle"> {{m?.code}} </td>

                        <td class="middle"> {{m?.feeType_Name}} </td>

                        <td class="center middle"> {{m?.amount | currency:currency+' '}}</td>

                        <td class="center middle"> {{m?.amountPaid | currency:currency+' '}}</td>
                        
                        <td class="center middle"> {{m?.status}} </td>
                        <td class="center middle"> {{m?.balance_Payable | currency:m?.currency+' '}}</td>

                        <td class="center middle"> 
                            <button *ngIf="!codes.includes(m.code)" (click)="Selected(m)" class="btn btn-outline-dark btn-sm"> Select  </button>  
                            <button *ngIf="codes.includes(m.code)" (click)="RemoveSelected(m)" class="btn btn-outline-white btn-danger btn-sm"> Cancel  </button> 
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <div class="border-0 p-0 m-0">
                <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)" class="text-center"> </pagination-controls>
              </div>
        </div>
    
        <br />
        <br />
        <section align="left" *ngIf="bills.length == 0 && !loading">
            <h3 class="font-weight-bold font-size-18 text-danger" translate> {{'PAYMENT_FOOTNOTE' | translate}}</h3>
            <p translate> {{'PAYMENT_FOOTNOTE_DESCRIPTION' | translate}}</p>
        </section>
    </div>
</div>

<!-- <pagination></pagination> -->