import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/core/api.service';
import { GlobalsService } from 'src/app/services/core/globals.service';
import { SigninService } from 'src/app/services/features/signin.service';
import { UsersService } from 'src/app/services/features/users';
import Swal from 'sweetalert2';

@Component({
  selector: 'password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.css']
})
export class PasswordFormComponent implements OnInit {

  password: any = [];  user: any; cp: any;
  @ViewChild('cpForm') public any: NgForm;
  saving: boolean = false;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 6000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(private api: ApiService, private router:Router, private use : SigninService) { }

  ngOnInit(): void {
    this.user = this.use.userDetails().data; console.log(this.user);
    this.formReset();
  }

  
  GetStarted() {
    this.api.post('password', this.password).subscribe((data:any) =>{
    },( err: HttpErrorResponse)=>{
      // console.log(err);
    });
  }

  // ChangePassword() {
  //   this.use.changePassword(this.cp).subscribe((data: any) => {
  //     this.cp = data;
  //     this.Toast.fire({
  //       icon: 'success',
  //       title: 'password changed',
  //     })
  //   }, (err: HttpErrorResponse) => {
  //     console.log(err)
  //     this.Toast.fire({
  //       icon: 'error',
  //       title: 'unable to change password',
  //     })
  //   });
  // }

  ChangePassword() {
    this.saving = true
    this.use.changePassword(this.cp).subscribe((data: any) => {
      this.cp = data;
      this.api.Toast.fire({
        icon: 'success',
        title: `<small> <b> Password changed!! </b></small>`,
      })
      document.getElementById('close').click();
    }, (err: HttpErrorResponse) => {
      this.api.Toast.fire({
        icon: 'error',
        title: `<small> <b> ${err} <br> unable to change password </b></small>`,
      })
    });
  }


  // formReset() {
  //     this.cp = {
  //        oldpassword: '', newpassword: ''
  //     };
  // }

  formReset() {
    this.cp = {
      oldpin: '', newpin: '',
    }; 
   }

}
