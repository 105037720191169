import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/core/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.css']
})
export class PaymentFormComponent implements OnInit {
  @Input() bills:any[] = [];
  @Input() total: number = 0;
  @Output() complete = new EventEmitter<any>();
  @Input() currency = environment.currency;
  data: any;
  constructor(public api: ApiService, private router: Router) { }

  ngOnInit(): void {
  }

  payBills() {
    // console.log(this.total);
    // if(0 > this.total) return;
    this.data = { walletNumber: this.api.wallet.code, currency: this.api.wallet.currency, amount: this.total, source: "PORTAL", bills: this.bills }
    this.api.post('student/post-fee-payment', this.data).subscribe((data: any) => {
      // console.log(data);
      this.api.success("Fees Payment was successful");
      this.complete.emit(data);
      document.getElementById('close').click();
      // location.reload();
      // this.router.navigate(['/fees','mandatory']);
    },(err:any)=>{
      this.api.error("Unable to pay fees");
    });
  }

  
  update(id:number, property:string, event:any) {
    const editField = parseInt(event.target.textContent);
    // console.log(editField)
    if(editField > 0) this.bills[id][property] = editField;
    this.getTotal();
  }

  change(id:number, property:string, event:any){
    const editField = parseInt(event.target.textContent);
    // console.log(editField, 'change');
    if(editField > 0) this.bills[id][property] = editField;
    this.getTotal();
  }

  getTotal() {
    this.total = this.bills.reduce((amount,bill)=>{
      return parseInt(amount) + parseInt(bill.amount);
    },0);
    // console.log(this.total);
  }

}
