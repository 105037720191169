import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/core/api.service';
// import { ConfirmedValidator } from 'src/app/services/features/customValidator';
// import { environment } from 'src/environments/environment.prod';
import Swal from 'sweetalert2';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'setpin-form',
  templateUrl: './setpin-form.component.html',
  styleUrls: ['./setpin-form.component.css']
})
export class SetpinFormComponent implements OnInit {
  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	SearchCountry = [SearchCountryField.Iso2, SearchCountryField.Name];
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.Ghana];

  submitted : Boolean = false; set: any; res: any; 
  @ViewChild('getStartedForm') public any: NgForm;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  constructor(private api: ApiService, private router:Router,  private route: ActivatedRoute) { }

 
  ngOnInit(): void {
    this.init();
  }

  init() {
    this.set = { newpin: '', indexNumber: '', schoolCode:environment.schoolCode, date: new Date()};
  }

  setpin() {
    console.log(this.set);
    this.submitted = true;
    this.set.indexNumber = this.set.mobile.e164Number;
    this.api.post('set-pin', this.set).subscribe((data:any) =>{
      this.Toast.fire({
        icon: 'success',
        title: 'pin set',
      })
      this.router.navigate(['/auth/login']);
    },( err: HttpErrorResponse)=>{
      this.Toast.fire({
        icon: 'error',
        title: `<small> <b> ${err}! </b></small>`,
      })
    });
  }

}
