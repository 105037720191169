<div id="layout-wrapper my-5">
  <div class="h-100">
      <div class="page-content">
          <div class="container-fluid position-absolute top-50 start-50 translate-middle">

              <div class="row center align-items-start justify-content-evenly">
                  <div class="col-md-4">
                      <div class="pb-3 mb-5">
                           <img style="width: 200px;"
                                  src="assets/img/logo.png" alt="">
                      </div>
                      <div class="">
                          <h2 class="dark fs-30 fw-bold">School Billing And Payment System</h2><br>
                          <p class="dark">School Billing made easy. Remove friction from managing all <br
                                  class="d-lg-inline d-md-none d-none"> academic payment processes, end-to-end</p><br>
                      </div>
                  </div>
                  <div class="col-md-5">
                      <div class="card">
                          <div class="card-body">

                              <h4 class="card-title dark fw-bold">Set your pin </h4>
                              <p class="dark"> Set your pin to continue</p>


                              <form role="form" method="Post" #pinForm="ngForm" (ngSubmit)="setpin()">

                                <div class="wrapper">
                                  <label for="mobile">Mobile Number *</label>
                                  <div class="form-group">
                                      <ngx-intl-tel-input [cssClass]="'form-control custom'"
                                          [preferredCountries]="preferredCountries"
                                          [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                          [searchCountryFlag]="true" [searchCountryField]="SearchCountry"
                                          [selectFirstCountry]="true"
                                          [selectedCountryISO]="CountryISO.Ghana"
                                          [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                          [numberFormat]="PhoneNumberFormat.National"
                                          [(ngModel)]="set.mobile" name="indexNumber" required>
                                        </ngx-intl-tel-input>
                                  </div>
                              </div>
                                
                                  <div class="form-group mb-4">
                                    <label class="control-label dark" translate>Your Pin *</label>
                                    <input [(ngModel)]="set.newpin" class="form-control" name="newpin" type="password" pattern=".{6}" maxlength="6" placeholder="enter pin" required>
                                    <small>pin must contain 6 characters</small>
                                  </div>
                              
                                <!-- <button routerLink="/app/overview" class="btn btn-danger btn-block" type="button" translate>Set Your Pin</button> -->
                                <button class="btn btn-danger btn-block" [disabled]="!pinForm.valid" type="submit" translate> <span *ngIf="submitted == false" class="fw-bold"> Set Your Pin </span>  <span *ngIf="submitted == true">please wait <i class="fas fa-spinner fa-spin"></i> </span></button>
                              </form> <br>
                              
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>