import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { ApiService } from 'src/app/services/core/api.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-funds-form',
  templateUrl: './funds-form.component.html',
  styleUrls: ['./funds-form.component.css']
})
export class FundsFormComponent implements OnInit {
  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	SearchCountry = [SearchCountryField.Iso2, SearchCountryField.Name];
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.Ghana];
  
  funds: any;
  wallets:any[] = [];
  networks: any[] = ["MTN", "AIRTELTIGO", "VODAFONE"];
  sh: any;
  isChecked: boolean = true;
  submitted : Boolean; 
  @ViewChild('fundsForm') public any: NgForm;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  constructor(private api: ApiService, private router:Router) { }

  ngOnInit(): void {
    this.init();
    this.getWallets();
  }

    init() {
    this.funds = {
      amount: null,
      method: 'Momo',
      network: null,
      mobile: {},
      wallet:null,
      currency: environment.currency,
      source: 'Web',
    };
  }

  getWallets() {
    this.api.get('student/wallet').subscribe((data: any) => {
      this.wallets = data.data; 
      console.log(data)
    }, (err: HttpErrorResponse) => {
      this.Toast.fire({
        icon: 'error',
        title: `<small> <b> ${err} </b></small>`,
      })
    });
  }

  addFunds() {
    this.submitted = true; 
    this.funds.mobile = this.funds.mobile.e164Number;
    this.api.post('student-topup', this.funds).subscribe((data: any) => {
    this.funds = '';
    this.Toast.fire({
        icon: 'success',
        title: `<small> <b> Please approve payment prompt sent to your phone </b></small>`,
      })
      setTimeout(() => {
        window.location.reload();
      }, 4000);
      this.router.navigate(['/app/overview']);
    }, (err: HttpErrorResponse) => {
      this.submitted = false;
      this.Toast.fire({
        icon: 'error',
        title: `<small> <b> ${err} </b></small>`,
      })
    });
  }

}
