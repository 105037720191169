import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { ApiService } from 'src/app/services/core/api.service';
import { GlobalsService } from 'src/app/services/core/globals.service';
import { UsersService } from 'src/app/services/features/users';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'getstarted-form',
  templateUrl: './getstarted-form.component.html',
  styleUrls: ['./getstarted-form.component.css']
})
export class GetstartedFormComponent implements OnInit {
  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	SearchCountry = [SearchCountryField.Iso2, SearchCountryField.Name];
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.Ghana];
  
  submitted : Boolean = false; get: any; res: any; 
  @ViewChild('getStartedForm') public any: NgForm;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  constructor(private api: ApiService, private _userService: UsersService, private _globals: GlobalsService, private router:Router) { }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.get = { mobile: '', indexNumber: '', schoolCode:environment.schoolCode, date: new Date()};
  }
  
  validateStudent() {
    
  }

  SendOtp() {
    this.submitted = true;
    console.log(this.get)
    this.get.indexNumber = this.get.mobile.e164Number;
    this.api.post('send-otp', this.get).subscribe((data: any) => {
      this.Toast.fire({
        icon: 'success',
        title: `<small> <b> otp sent to your number </b></small>`,
      });
      const params = { indexnumber: this.get.indexNumber }
      this.router.navigate(['/auth/confirm'], { queryParams: params });
    }, (err: HttpErrorResponse) => {
      this.submitted = false;
      this.Toast.fire({
        icon: 'error',
        title: `<small> <b> ${err} </b></small>`,
      })
    });
  }

}
