import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/core/api.service';
import { GlobalsService } from 'src/app/services/core/globals.service';
import { UsersService } from 'src/app/services/features/users';
import Swal from 'sweetalert2';

@Component({
  selector: 'enroll-form',
  templateUrl: './enroll-form.component.html',
  styleUrls: ['./enroll-form.component.css']
})
export class EnrollFormComponent implements OnInit {

  submitted : Boolean = false; enroll: any; res: any; 
  @ViewChild('enrollForm') public any: NgForm;

  readonly Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(private api: ApiService, private _userService: UsersService, private _globals: GlobalsService) { }

  ngOnInit(): void {
    this.init()
  }

  init() {
    this.enroll = { campus: '', department: '', intake: '', sponsorship: '', course: '', session: '', date: new Date()};
  }

  
  Enroll() {
    this.api.post('enroll/', this.enroll).subscribe((data:any) =>{
    },( err: HttpErrorResponse)=>{
      // console.log(err);
    });
  }

  Enrollment() {
    console.log(this.enroll)
    this.api.post('enroll/', this.enroll).subscribe((data: any) => {
      this.res = data;
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'account created',
        showConfirmButton: false,
        timer: 1500
      })
    }, (err: HttpErrorResponse) => {
      this.Toast.fire({
        icon: 'error',
        title: 'Unable to create account',
      })
    });
  }

}
